import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LayOut from "src/layouts/myLeadershipSurvey/myLeadershipStartPageLayout";

const Disclaimer = lazy (() => import("src/layouts/myLeadershipSurvey/components/disclaimer"));

export const myLeadershipSurveyRoutes = [
    {
        path:"",
        element: (
            <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </LayOut>
        ),
        children :[
            {
                path:"myLeadershipSurvey",
                index:true,
                element : <Disclaimer/>
            }
        ]
    }
]