/**
 * @Author: Anandp
 * @Date:   2024-09-09 17:17:39
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-09-21 15:16:54
 */
// src/utils/joyrideSteps.js
import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";

export const getJoyrideSteps = (pathname) => {
    switch (pathname) {
        case '/leadSelf':
            return [
              {
                content: (<div>
                  <NavigateStart />
                  <h2>Welcome to your <br />Personality Profile</h2>
                  <p>Take the tour to discover the new features</p>
                </div>),
                locale: { next: 'Start Tour!' },
                placement: 'center',
                target: 'body',
                title: 'Start'
              },
              {
                content: 'View the results from your personality surveys here. Click through the different headings to read through your personality profile description',
                placement: 'top-start',
                target: '.joyride-target-pp1',
              },
              {
                content: 'Click here to view your Wired-Up survey clarity. Based on your personality profile description validate your results here​',
                placement: 'auto',
                styles: {
                  options: {
                    width: 300,
                  },
                },
                target: '.joyride-target-pp2',
      
              },
              {
                content: (
                  <div>
                    Click here to review your Work-With clarity and validate your results
                    <br />
                  </div>
                ),
                placement: 'auto',
                target: '.joyride-target-pp3',
      
              },
              {
                content: (
                  <div>
                    Info icons are available throughout the dashboard. Click on these to get further information about what the tiles mean
                    <br />
                  </div>
                ),
                placement: 'auto',
                target: '.joyride-target-pp5',
      
              },
              {
                content: (
                  <div>
                    You can download the results on this page as a PDF by clicking here
                  </div>
                ),
                placement: 'auto',
                target: '.joyride-target-4',
                locale: { next: 'Finish' },
                title: 'Last'
      
              },
      
                // ... other steps
            ];
        case '/myLeadership':
            return [
              {
                content: (
                  <div>
                    <NavigateStart />
                    <h2>Welcome to My Leadership</h2>
                    <p>Take this quick tour to learn how to interpret your 063 results.</p>
                  </div>
                ),
                locale: { next: 'Lets Go!' },
                placement: 'center',
                target: 'body',
              },
              {
                content: 'View detailed results for each category by clicking into the bars',
                placement: 'auto',
                target: '.joyride-target-my1',
                scrollOffset: 100,
              },
              {
                content: 'Click on the + to view the tips for each question. The comments are colour coded based on whether they are from yourself, a colleague or your bosses',
                placement: 'left',
                target: '.joyride-target-my2',
              },
              {
                content: 'Click here to view the comments from colleagues on your Leadership Brand Index',
                placement: 'auto',
                target: '.joyride-target-my3',
              },
              {
                content: 'Click here to view the comments from colleagues on your Step Up Index',
                placement: 'auto',
                target: '.joyride-target-my4',
              },
              {
                content: 'A detailed 063 report is downloadable here. If you have completed a 063 with us previously, the previous results will be available here',
                placement: 'auto',
                target: '.joyride-target-my5',
                title: 'Last'
      
              },
              // Add more steps for another URL
            ];

        case '/myExecutiveHealth':
                return [
                  {
                    content: (
                      <div>
                        <NavigateStart />
                        <h2>Welcome to My Executive Health​</h2>
                        <p>Take this quick tour to learn how to interpret your Executive Health results.</p>
                      </div>
                    ),
                    locale: { next: 'Lets Go!' },
                    placement: 'center',
                    target: 'body',
                  },
                  {
                    content: 'Details of your BMI, WHR and Blood pressure are displayed here',
                    placement: 'top',
                    target: '.joyride-target-me1',
                  },
                  {
                    content: 'View detailed results for each category by clicking into the bars',
                    placement: 'auto',
                    target: '.joyride-target-me2',
                  },
                  {
                    content: 'Any comments left by your health and resilience coach will be visible here',
                    placement: 'auto',
                    target: '.joyride-target-me3',
                    title: 'Last'
          
                  },
                ];
        case '/leadSelfSettings':
                return [
                  {
                    content: (
                      <div>
                        <NavigateStart />
                        <h2>Welcome to Lead Self Settings</h2>
                        <p>Take the tour to learn how to manage your teams and request surveys​</p>
                      </div>
                    ),
                    locale: { next: 'Start Tour!' },
                    placement: 'center',
                    target: 'body',
                    id:'jrls-1'
                  },
                  {
                    content: 'Here you can view the list of teams you are able to manage. Click on the dropdown to select a team',
                    placement: 'left',
                    target: '.joyride-target-ls1',
                    spotlightClicks: true,
                    id:'jrls-2'
                  },
                  {
                    content: 'The details of the selected team will be displayed here',
                    placement: 'bottom-start',
                    target: '.joyride-target-ls2',
                    spotlightClicks: false,
                    id:'jrls-3',
                    width:'200'
                  },

                  {
                    content: 'Select the necessary team members and proceed to complete any of these functions',
                    placement: 'bottom-start',
                    target: '.joyride-target-lsn2',
                    spotlightClicks: true,
                    id:'jrls-3n',
                    width:'200',
                    title: 'Last'
                  },
                  
                 
                  
                ];
        // Add more cases for other paths
        default:
            return [];
    }
};
