import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LayOut from "src/layouts/MTPSurvey/MTPStartPageLayout";
const Disclaimer = lazy(() => import("src/layouts/MTPSurvey/components/disclaimer"));

export const MTPSUrveyRoutes = [
    {
        path: "",
        element : (
            <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </LayOut>
        ),
        children : [
            {
                path:"MTPsurvey",
                index:true,
                element:<Disclaimer/>
            }
        ]
    }
]