import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";


const PrivacyPolicy= lazy(()=>import("src/pages/privacyPolicy"));

export const privacyPolicy = [
  {
    path: "",
    element: (  
        <Suspense>    
          <Outlet />
        </Suspense>
    ),
    children: [
      {
        path: "privacyPolicy",
        index: true,
        element: <PrivacyPolicy />,
      }
    ],
  },
];
