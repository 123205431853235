import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import AddGoalLayout from "src/layouts/goalAndPulses/addGoalLayout";
import { RowIdProvider } from "src/contexts/goals-and-pulse-context";

const AddGoal1 = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/addGoals/addGoal1")
);
const AddGoal2 = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/addGoals/addGoal2")
);
const AddGoal3 = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/addGoals/addGoal3")
);
const GoalReview = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/addGoals/goalReview")
);
const NewGoalAdded = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/addGoals/newGoalAdded")
);
const EditGoal = lazy(() =>
  import("src/pages/goalsAndPulses/overviews/editGoal/editGoal")
);
const EditGoal1 = lazy(() => 
  import("src/pages/goalsAndPulses/overviews/editGoalInformation/editGoal1")
);
const EditGoal2 = lazy(() => 
  import("src/pages/goalsAndPulses/overviews/editGoalInformation/editGoal2")
);
const EditGoal3 = lazy(() => 
  import("src/pages/goalsAndPulses/overviews/editGoalInformation/editGoal3")
);
const GoalEditReview = lazy(() => 
  import("src/pages/goalsAndPulses/overviews/editGoalInformation/goalEditReview")
);

export const AddAndEditGoalRoute = [
  {
    path: "",
    element: (
      <RowIdProvider>
        <AddGoalLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AddGoalLayout>
      </RowIdProvider>
    ),
    children: [
      {
        path: "addGoal1",
        index: true,
        element: <AddGoal1 />,
      },
      {
        path: "addGoal2",
        index: false,
        element: <AddGoal2 />,
      },
      {
        path: "addGoal3",
        index: false,
        element: <AddGoal3 />,
      },
      {
        path: "goalReview",
        index: false,
        element: <GoalReview />,
      },
      {
        path: "newGoalAdded",
        index: false,
        element: <NewGoalAdded />,
      },
      {
        path: "editGoal/:id",
        index: false,
        element: <EditGoal />,
      },
      {
        path: "editGoal1/:id",
        index: false,
        element: <EditGoal1 />,
      },
      {
        path: "editGoal2/:id",
        index: false,
        element: <EditGoal2 />,
      },
      {
        path: "editGoal3/:id",
        index: false,
        element: <EditGoal3 />,
      },
      {
        path: "goalEditReview/:id",
        index: false,
        element: <GoalEditReview />,
      },
    ],
  },
];
