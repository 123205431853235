import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import Box from '@mui/material/Box';
import * as SldButtons from "src/theme/base/SldButtons";
import Collapse from '@mui/material/Collapse';
import SvgIcon from '@mui/material/SvgIcon';
import { purple } from "src/theme/colors";
import { RouterLink } from 'src/components/router-link';

export const SideNavItem = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    open: openProp,
    path,
    title,
    // eslint-disable-next-line 
    updatetitle,
    isMinimized
  } = props;
  
  const [open, setOpen] = useState(!!openProp);
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleChildClick = () => {
    setIsActive(true);
  };

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  if (children) {
    return (
      <li>
        <SldButtons.NavbarButton
          disabled={disabled}
          onClick={handleToggle}
          className={isActive ? 'active' : ''}
          sx={{
            pl: `${16 + offset}px`,
            ...(isActive && {
              backgroundColor: "var(--nav-item-active-bg)",
              '& svg path': { fill: 'white' }
            }),
          }}
        >
          {icon && (
            <Box
              component="span"
              sx={{
                ...(isActive && {
                  color: `${purple.main} !important`,
                  "& .MuiBox-root .MuiSvgIcon-root": {
                    margin: "0 0px 0 0!important",
                    color: `${purple.main} !important`,
                    "& path": {
                      fill: `${purple.main} !important`,
                    },
                  },
                }),
                "&:hover": {
                  color: "white",
                },
              }}
            >
              {icon}
            </Box>
          )}
          <Box
            component="span"
            sx={{
              color: "var(--nav-item-color)",
              flexGrow: 1,
              fontFamily: (theme) => theme.typography.fontFamily,
              fontSize: depth > 0 ? 13 : 14,
              fontWeight: depth > 0 ? 500 : 600,
              lineHeight: "24px",
              whiteSpace: "nowrap",
              ...(isActive && {
                color: "var(--nav-item-active-color)",
              }),
              ...(disabled && {
                color: "var(--nav-item-disabled-color)",
              }),
            }}
          >
            {title}
          </Box>
          <SvgIcon
            sx={{
              color: "var(--nav-item-chevron-color)",
              fontSize: 16,
              ml: 2,
            }}
          >
            {open ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </SldButtons.NavbarButton>
        <Collapse in={open} 

        sx={{ mt: 0.5 }}>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              active: isActive,
              onClick: handleChildClick,
            });
          })}
        </Collapse>
      </li>
    );
  }

  const linkProps = path
    ? external
      ? {
        component: 'a',
        href: path,
        target: '_blank'
      }
      : {
        component: RouterLink,
        href: path,
      }
    : {};

  return (
    <li>
      <SldButtons.NavbarButton
        disabled={disabled}
        className={isActive ? 'active' : ''}
        sx={{
          pl: `${16 + offset}px`,
          ...(isActive && {
            backgroundColor: `${purple.main} !important`,
            color: `#fff !important`,
            '& svg path': { fill: 'white' },
            '&.MuiButtonBase-root.active path': { fill: 'white' },
          }),
        }}
        {...linkProps}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              ...(isActive && {
                color: `white !important`,
                '& .MuiBox-root .MuiSvgIcon-root': { margin: '0 0px 0 0!important', fill: 'white', color: `white!important`,
                  '& path': {
                    fill: `white !important`,
                  }
                },
              }),
              "&:hover": {
                color: 'white',
                '& .MuiBox-root .MuiSvgIcon-root': { margin: '0 0px 0 0!important', color: `white!important`,
                  '& path': {
                    fill: `white!important`,
                  }
                },
              },
            }}
          >
            {icon}
          </Box>
        )}
         {!isMinimized && (<Box
          component="span"
          sx={{
            color: "var(--nav-item-color)",
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: depth > 0 ? 13 : 14,
            fontWeight: depth > 0 ? 500 : 600,

            ...(isActive && {
              color: "#fff!important",
            }),
            ...(disabled && {
              color: "#fff",
            }),
          }}
        >
          {title}
        </Box>)}
        {label && !isMinimized &&(
          <Box
            component="span"
            sx={{ ml: 2 }}
          >
            {label}
          </Box>
        )}
      </SldButtons.NavbarButton>
    </li>
  );
};

SideNavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isMinimized: PropTypes.bool.isRequired,
};
