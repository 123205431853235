export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    goalAndPulses:"nav.goalAndPulses",
    conversationWithBoss:"nav.conversationWithBoss",
    islProgrammes:'nav.islProgrammes',
    academy: 'nav.academy',
    leadership: 'nav.leadership',
    personalityProfile: 'nav.personalityProfile',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    menu: 'nav.menu',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    executiveHealth: 'nav.executiveHealth',
    Organizations: 'nav.organization',
    Users:'nav.users',
    Dashboard: 'nav.dashboard',
    details: 'nav.details',
    myMojo: 'nav.myMojo',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    home: 'nav.home',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',

    roleManagement: 'nav.roleManagement',
    roles:'nav.roles',
    permissions:'nav.permissions'
  }
};
