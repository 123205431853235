import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";


const UnSubscribe= lazy(()=>import("src/pages/unSubscribe"));

export const unSubscribe = [
  {
    path: "",
    element: (  
        <Suspense>    
          <Outlet />
        </Suspense>
    ),
    children: [
      {
        path: "unSubscribe",
        index: true,
        element: <UnSubscribe/>,
      }
    ],
  },
];
