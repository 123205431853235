import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { ExplorerLayout } from "src/layouts/ExplorePage";

const ExplorerPage = lazy(() =>import("src/pages/explorerPage/exploreePage"));

export const exploreRoutes = [
  {
    path: "",
    element: (
      <AuthGuard>
        <ExplorerLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </ExplorerLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: "explorerPage",
        index: true,
        element: <ExplorerPage />,
      },
    ],
  },
];