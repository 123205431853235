import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import * as SldButtons from "src/theme/base/SldButtons";
// eslint-disable-next-line 
import customerApiService from "src/services/customerApi";
//import loginApiService from "src/services/loginVerifyAPI";
import { useUser } from "src/hooks/use-user";

// eslint-disable-next-line 
import CryptoJS from 'crypto-js';

const CustomTooltip = ({
  step,
  primaryProps,
  backProps,
  skipProps,
  continuous,
  index,
  size,

}) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const user = useUser();
  const [urlClass, setUrlClass] = useState('');
  const progressPercentage = ((index + 1) / size) * 100;
  const getButtonText = () => {
    if (step.title === 'Last') {
      return 'Finish';
    } else if (index === 0 && step.title === 'Start') {
      return 'Start Tour!';
    } else if (index === 0) {
      return 'Lets go!';
    } else {
      return 'Next';
    }
  };
  const buttonText = getButtonText();
  useEffect(() => {
    const currentPath = window.location.pathname.replace(/\//g, '-'); // Replace slashes with dashes
    setUrlClass(`tooltip-${currentPath}`);
  }, []);
  const updateFinish = async () => {
    try {
      console.log('---insidee update finish---');
      
      let body = {};

      // Get the current URL path
      const currentPath = window.location.pathname;

      // Conditionally set the body based on the URL
      if (currentPath === "/dashboard") {
        body = { "dashboard": 1 };
      } else if (currentPath === "/leadSelf") {
        body = { "leadSelf": 1 };
      }
      else if (currentPath === "/myLeadership") {
        body = { "myLeadership": 1 };
      }
      else if (currentPath === "/myExecutiveHealth") {
        body = { "myExecutiveHealth": 1 };
      }
      else if (currentPath === "/leadershipBrand") {
        body = { "leadershipBrand": 1 };
      }
      else if (currentPath === "/clickWith") {
        body = { "clickWith": 1 };
      }
      else if (currentPath === "/teamCompatability") {
        body = { "teamCompatability": 1 };
      }
      else if (currentPath === "/MTP") {
        body = { "MTP": 1 };
      }
      else if (currentPath === "/hranalytics") {
        body = { "hranalytics": 1 };
      }
      else if (currentPath === "/mtpSettingsTeamSelect") {
        body = { "mtpSettingsTeamSelect": 1 };
      }
      else if (currentPath === "/mtpSettings") {
        console.log('---inside mtp settings---finish');
        
        body = { "mtpSettings": 1 };
      }
      else if (currentPath === "/leadSelfSettings") {
        body = { "leadSelfSettings": 1 };
      }
      console.log("Updating Joyride with body:", body);
      let data = await customerApiService.updateJoyride(accessToken, body);
      if (data) {
        await customerApiService.getJoyride(accessToken, user.id);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleFinish = async (event) => {
    event.preventDefault(); // Ensure the default action is prevented
    console.log('handleFinish called');
    await updateFinish();
    if (primaryProps.onClick) {
      primaryProps.onClick(event);
    }
  };

  const handleSkip = async (event) => {
    event.preventDefault(); // Ensure the default action is prevented
    console.log('handleSkip called');
    await updateFinish();
    if (skipProps.onClick) {
      skipProps.onClick(event);
    }
  };



  return (
    <div className={`react-joyride__tooltip ${urlClass}`}
      style={{ padding: 0, maxWidth: 280 }}>
      <div className='topbar'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 10, paddingRight: 10, paddingTop: 10, fontSize: 12 }}>
        <div className="steps">{index + 1}/{size}</div>
        <button {...skipProps}
onClick={handleSkip}
          style={{ border: 'none', background: 'transparent', cursor: 'pointer', fontSize: '1rem', color: '#fff' }}>
          ✕
        </button>
      </div>
      <div style={{ height: 5, backgroundColor: 'rgba(255,255,255,.1)', borderRadius: 5, marginTop: 5 }}>
        <div
          style={{
            height: '100%',
            width: `${progressPercentage}%`,
            backgroundColor: 'white',
            borderRadius: 5,
          }}
        />
      </div>
      <div style={{ marginTop: 0, paddingLeft: 10, paddingRight: 10, paddingTop: 10, fontSize: 13 }}>
        {step.content}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginTop: 10, padding: 10 }}>
        {index > 0 && (



          <SldButtons.WhiteButton {...backProps}
            sx={{ backgroundColor: 'rgba(88, 63, 153, 1)', padding: '2px 8px', color: '#fff', fontSize: 12 }}>
            <ArrowBackIcon style={{ marginRight: 5, width: '15px' }} /> Back
          </SldButtons.WhiteButton>
        )}
        <SldButtons.WhiteButton {...primaryProps}
onClick={step.title === 'Last' ? handleFinish : primaryProps.onClick}
          sx={{ display: 'flex', alignItems: 'center', padding: '2px 8px', color: '#583f99', fontSize: 12 }}>
          {buttonText}<ArrowForwardIcon style={{ marginLeft: 5, width: '15px' }} />
        </SldButtons.WhiteButton>
      </div>
    </div>
  );
};

export default CustomTooltip;
