import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import Layout  from "src/layouts/wiredUpSurveys/WiredUpStartPageLayout";

const Disclaimer = lazy(() =>
  import("src/layouts/wiredUpSurveys/surveys/disclaimer")
);
const WiredUp = lazy(()=>import("src/layouts/wiredUpSurveys/surveys/wiredUpSurvey"))
export const wiredUpSurveyRoutes = [
  {
    path: "",
    element: (
      <Layout>
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      {
        path: "wiredUpSurvey",
        index: true,
        element: <Disclaimer />,
      },
      {
        path: "wiredUp",
        index: true,
        element: <WiredUp />,
      },
    ],
  },
];
