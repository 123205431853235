const HomeSmile = (props) => (
<svg width="18"
height="19"
viewBox="0 0 18 19"
fill="none"
xmlns="http://www.w3.org/2000/svg">
<path d="M9 2.30005C8.04522 2.30005 7.12954 2.67933 6.45441 3.35446C5.77928 4.0296 5.4 4.94527 5.4 5.90005C5.4 6.85483 5.77928 7.7705 6.45441 8.44563C7.12954 9.12076 8.04522 9.50005 9 9.50005C9.95478 9.50005 10.8705 9.12076 11.5456 8.44563C12.2207 7.7705 12.6 6.85483 12.6 5.90005C12.6 4.94527 12.2207 4.0296 11.5456 3.35446C10.8705 2.67933 9.95478 2.30005 9 2.30005ZM4.5081 10.4C4.27107 10.3991 4.03619 10.445 3.81692 10.535C3.59765 10.625 3.39831 10.7574 3.23033 10.9247C3.06235 11.0919 2.92903 11.2906 2.83803 11.5095C2.74702 11.7283 2.70011 11.963 2.7 12.2C2.7 13.7219 3.4497 14.8694 4.6215 15.6173C5.7753 16.3526 7.3305 16.7 9 16.7C10.6695 16.7 12.2247 16.3526 13.3785 15.6173C14.5503 14.8703 15.3 13.721 15.3 12.2C15.3 11.7227 15.1104 11.2648 14.7728 10.9273C14.4352 10.5897 13.9774 10.4 13.5 10.4H4.5081Z"
fill="#535B66"/>
</svg>

  
);

export default HomeSmile;
