/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-23 22:17:22
 */

export const paths = {
  index: "/",
  privacyPolicy:"/privacypolicy",
  unSubscribe:"/unSubscribe", 
  ssoLogin: "/sso",
  selectProfile: "/profileswitch",
  dashboard: {
    index: "/dashboard",
    account: "/dashboard/account",
    blank: "/dashboard/blank",
    organizations: {
      index: "/organizations",
      details: "/organizations/:customerId",
      edit: "/organizations/:customerId/edit",
      add: "/organizations/add",
    },
    leadself: {
      index: "/leadSelf",
    },
    leadselfSettings:{
      index: "/leadSelfSettings",
    },
    islProgrammes: {
      goalsAndPulses: "/goalsAndPulses",
    },
    mymojo: {
      index: "/myMojo",
    },
    team: {
      index: "/leadershipBrand",
    },
    myExecutiveHealth: {
      index: "/myExecutiveHealth",
    },
    myExecutiveHealthKPI:{
      index:"/executiveHealthKPI"
    },
    leadershipBrand: {
      index: "/leadershipBrand",
    },
    myLeadership: {
      index: "/myLeadership",
    },
    conversationWithBoss: {
      index: "/conversationWithBoss",
    },
    coachesCorner: {
      index: "/coachescorner",
    },
    teamMojo: {
      index: "/teamMojo",
    },
    teamDiversity: {
      index: "/teamDiversity",
    },
    clickWith: {
      index: "/clickWith",
    },
    teamDevelopment: {
      index: "/teamDevelopment",
    },
    mtp: {
      index: "/MTP",
    },
    myPulseTrends: {
      index: "/myPulseTrends",
    },
    mtpSettings: {
      index: "/mtpSettingsTeamSelect",
    },
    hrAnalytics: {
      index: "/hranalytics",
    },
    mtpOrgNarrative: {
      index: "/mtporgnarrative",
    },
    teamCompatability: {
      index: "/teamCompatability",
    },
    users: {
      index: "/users",
      details: "/users/:userId",
      edit: "/users/:userId/edit",
      add: "/users/add",
    },
    invoices: {
      index: "/dashboard/invoices",
      details: "/dashboard/invoices/:orderId",
    },
  },
  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
};
