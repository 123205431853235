import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { paths } from "src/paths";
export const useSections = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        path: paths.dashboard.leadself.index,
        items: [
          {
            title: "Menu",
            path: paths.dashboard.index,
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
};
