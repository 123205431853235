import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";


const GoalsAndPulseFeedback = lazy(()=>import("src/pages/goalsAndPulses/goalsAndPulseFeedback"));

export const GoalsAndPulseFeedbackRoute = [
  {
    path: "",
    element: (  
        <Suspense>    
          <Outlet />
        </Suspense>
    ),
    children: [
      {
        path: "GoalsAndPulseFeedback",
        index: true,
        element: <GoalsAndPulseFeedback />,
      }
    ],
  },
];
