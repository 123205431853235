import React, { createContext, useContext, useState } from 'react';

const durationContext = createContext();

export const DurationProvider = ({ children }) => {
  const [selectedDuration, setSelectedDuration] = useState("monthly");

  const setDuration = (duration) => {
    setSelectedDuration(duration);
  };

  return (
    <durationContext.Provider value={{ selectedDuration, setDuration }}>
      {children}
    </durationContext.Provider>
  );
};

export const useDuration = () => {
  return useContext(durationContext);
};