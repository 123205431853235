import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Typography,
  Grid,
  Box,
  TextField,
  Stack,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as SldButtons from "src/theme/base/SldButtons";
// import toast from "react-hot-toast";
import { toast } from 'react-toastify';
import { Url } from "src/config";
import dayjs from "dayjs";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => (
  <Dialog open={isOpen}
onClose={onClose}
maxWidth="sm"
fullWidth>
    <DialogTitle>Confirm Submission</DialogTitle>
    <DialogContent>
      <Typography>
        Please review that all the information you have entered in the request
        is accurate before submitting.
      </Typography>
    </DialogContent>
    <DialogActions>
      <SldButtons.BlackButtonO onClick={onClose}
color="primary">
        Cancel
      </SldButtons.BlackButtonO>
      <SldButtons.PurpleButton onClick={onConfirm}
color="primary">
        Confirm
      </SldButtons.PurpleButton>
    </DialogActions>
  </Dialog>
);

// const accessToken = sessionStorage.getItem("accessToken");

export const RequestSurveysModal = ({
  isOpen,
  onClose,
  selectedRows,
  categoryId,
  category_name,
  setSelectedRows,
  setSelectAll,
}) => {
  const [surveys, setSurveys] = useState([]);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [surveyData, setSurveyData] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchSurveys();
    }
  }, [isOpen]);

  /**
 * Asynchronous function that fetches surveys from the server.
 * It sends a GET request to retrieve surveys and updates the state with the fetched data.
 * If the request is successful, the surveys are stored in the `surveys` state,
 * and a corresponding `surveyData` state is populated with survey information (survey id and default time).
 * If the request fails, the error is caught and appropriate error messages are shown.
 *
 * @async
 * @function fetchSurveys
 * @returns {Promise<void>} - No return value; updates the state with survey data or handles errors.
 *
 * @throws {Error} If there's an error with the request or server, an error message is logged to the console
 * and displayed to the user via a toast.
 *
 * @example
 * Fetch surveys when the component or modal is opened (via `useEffect`)
 * fetchSurveys();
 */

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(`${Url}/customer/surveys`, {
        headers: {
          "access-token": sessionStorage.getItem("accessToken"),
        },
      });
      setSurveys(response.data.details);
      setSurveyData(
        response.data.details.map((survey) => ({
          id: survey.id,
          date: null,
          time: dayjs().set("hour", 7).set("minute", 30), // Default time to 7:30 AM
        }))
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          console.error(
            "Error 403: Access forbidden. Please check your permissions or token validity."
          );
          toast.error(
            "Access forbidden. Please check your permissions or token validity."
          );
        } else {
          console.error(`Error fetching surveys: ${error.response.statusText}`);
          toast.error(`Error fetching surveys: ${error.response.statusText}`);
        }
      } else {
        console.error("Error fetching surveys:", error.message);
        toast.error("Error fetching surveys. Please try again later.");
      }
    }
  };

  /**
 * Toggles the selection of a survey based on its ID.
 * If the survey is already selected, it removes it from the selected surveys array.
 * If the survey is not selected, it adds it to the array of selected surveys.
 * Additionally, if the survey is deselected, it resets its date and time to default values (null date and 7:30 AM).
 *
 * @function handleToggleSurvey
 * @param {string|number} surveyId - The ID of the survey to toggle.
 * @returns {void} - No return value. It updates the `selectedSurveys` and `surveyData` states.
 *
 * @example
 * Toggles the selection of a survey when a checkbox or similar UI element is clicked
 * handleToggleSurvey(surveyId);
 */

  const handleToggleSurvey = (surveyId) => {
    setSelectedSurveys((prev) =>
      prev.includes(surveyId)
        ? prev.filter((id) => id !== surveyId)
        : [...prev, surveyId]
    );
    if (selectedSurveys.includes(surveyId)) {
      setSurveyData((prev) =>
        prev.map((survey) =>
          survey.id === surveyId
            ? {
                ...survey,
                date: null,
                time: dayjs().set("hour", 7).set("minute", 30),
              }
            : survey
        )
      );
    }
  };

  /**
 * Validates if all selected surveys have a date set before sending the request.
 * If any selected survey does not have a date, an error message is shown.
 * If all selected surveys have dates, a confirmation dialog is opened.
 *
 * @function handleSendRequest
 * @returns {void} - No return value. It either shows an error message or opens a confirmation dialog.
 *
 * @example
 * Trigger the send request process when user clicks the "Send" button
 * handleSendRequest();
 */

  const handleSendRequest = () => {
    const invalidSurvey = surveyData.find(
      (survey) => selectedSurveys.includes(survey.id) && !survey.date
    );
    if (invalidSurvey) {
      toast.error("Please select a date to send surveys.");
      return;
    }

    setIsConfirmationOpen(true);
  };

  /**
 * Handles the confirmation of the survey request.
 * This function gathers the selected survey data and user data, formats the necessary details,
 * and sends a request to the backend to request surveys for the selected users.
 * If the request is successful, it resets the form and shows a success message.
 *
 * @function handleConfirm
 * @returns {void} - No return value. It either sends the survey request or handles any errors.
 *
 * @example
 * Trigger the confirmation process for requesting surveys
 * handleConfirm();
 */

  const handleConfirm = () => {
    const requestData = selectedSurveys.flatMap((surveyId) => {
      const survey = surveyData.find((data) => data.id === surveyId);
      const surveyDetails = surveys.find((data) => data.id === surveyId);

      let createdOn = null;

      if (survey.date) {
        createdOn = `${survey.date.format("DD/MM/YYYY")} ${(
          survey.time || dayjs().set("hour", 7).set("minute", 30)
        ).format("hh:mm A")}`;
      }

      return selectedRows.map((row) => ({
        userId: parseInt(row.id),
        user_name: String(row.userName),
        survey_id: parseInt(surveyId),
        survey_name: String(surveyDetails.name),
        createdOn: createdOn,
      }));
    });

    const payload = {
      type: "request_survey",
      category_id: parseInt(categoryId),
      category_name: String(category_name),
      reqData: requestData,
    };

    axios
      .post(`${Url}/customer/request_survey`, payload, {
        headers: {
          "access-token": sessionStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log("Request successful:", response.data.message);
          setIsConfirmationOpen(false);
          toast.success("Request survey sent successfully");
          setSelectedSurveys([]);
          setSurveyData(
            surveys.map((survey) => ({
              id: survey.id,
              date: null,
              time: dayjs().set("hour", 7).set("minute", 30), // Default time to 7:30 AM
            }))
          );
          setSelectedRows([]);
          setSelectAll(false);
          onClose();
        } else {
          throw new Error(response.data.message || "Failed to request survey");
        }
      })
      .catch((error) => {
        console.error("Error requesting survey:", error);
        toast.error("Failed to request survey. Please try again later.");
      });
  };

  /**
 * Handles the change of the date for a specific survey in the survey data.
 * This function updates the `date` field of a survey at a given index in the survey data array.
 *
 * @function handleDateChange
 * @param {number} index - The index of the survey to update in the survey data array.
 * @param {object} newDate - The new date to set for the specified survey. This is typically a `dayjs` object or a date format.
 * @returns {void} - No return value. It updates the state for the survey data.
 *
 * @example
 * Change the date of the first survey to a new date
 * handleDateChange(0, dayjs('2024-12-01')); 
 */

  const handleDateChange = (index, newDate) => {
    setSurveyData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], date: newDate };
      return newData;
    });
  };

  /**
 * Handles the change of the time for a specific survey in the survey data.
 * This function updates the `time` field of a survey at a given index in the survey data array.
 *
 * @function handleTimeChange
 * @param {number} index - The index of the survey to update in the survey data array.
 * @param {object} newTime - The new time to set for the specified survey. This is typically a `dayjs` object or a valid time format.
 * @returns {void} - No return value. It updates the state for the survey data.
 *
 * @example
 * Change the time of the first survey to a new time
 * handleTimeChange(0, dayjs().set("hour", 9).set("minute", 0)); 
 */

  const handleTimeChange = (index, newTime) => {
    setSurveyData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], time: newTime };
      return newData;
    });
  };

  const handleCancel = () => {
    setSelectedSurveys([]);
    setSurveyData((prev) =>
      prev.map((survey) => ({
        ...survey,
        date: null,
        time: dayjs().set("hour", 7).set("minute", 30),
      }))
    );
    setIsConfirmationOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen}
onClose={onClose}
maxWidth="md"
fullWidth>
        <DialogTitle>Request Surveys - {category_name}</DialogTitle>
        <Typography variant="caption"
marginLeft={3}>
          From the list of surveys below please select the one you would like to
          request to be sent to the selected team members.
        </Typography>
        <DialogContent>
          <Box mt={4}>
            {surveys.map((survey, index) => (
              <Grid
                container
                alignItems="center"
                key={survey.id}
                style={{ marginBottom: "10px" }}
              >
                <Grid
                  item
                  xs={11}
                  style={{
                    border: "1px solid lightgrey",
                    padding: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0",
                      },
                      zIndex: "30001",
                    }}
                  >
                    <Grid item
xs={6}>
                      <Typography color="primary">{survey.name}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      alignItems="center"
                      className="joyride-target-ls4"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {selectedSurveys.includes(survey.id) && (
                          <Stack direction="row"
alignItems="center">
                            <DatePicker
                              value={surveyData[index]?.date || null}
                              onChange={(newDate) =>
                                handleDateChange(index, newDate)
                              }
                              shouldDisableDate={(date) =>
                                date.isBefore(dayjs().startOf("day"))
                              }
                              renderInput={(params) => (
                                <Box sx={{ mt: 1 }}>
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </Box>
                              )}
                              inputFormat="DD/MM/YYYY"
                              format="DD/MM/YYYY"
                              sx={{
                                "& .MuiInputBase-input": {
                                  paddingTop: "10px",
                                  paddingLeft: "36px",
                                },
                                "& .MuiInputBase-root": {
                                  border: "none",
                                  "&::before, &::after": {
                                    content: "none",
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            />
                            <TimePicker
                              value={
                                surveyData[index]?.time ||
                                dayjs().set("hour", 7).set("minute", 30)
                              }
                              onChange={(newTime) =>
                                handleTimeChange(index, newTime)
                              }
                              renderInput={(params) => (
                                <Box sx={{ mt: 1 }}>
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                  />
                                </Box>
                              )}
                              sx={{
                                "& .MuiInputBase-input": {
                                  paddingTop: "10px",
                                  paddingLeft: "48px",
                                },
                                "& .MuiInputBase-root": {
                                  border: "none",
                                  "&::before, &::after": {
                                    content: "none",
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                },
                              }}
                            />
                          </Stack>
                        )}
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item
className="joyride-target-ls3"
xs={1}>
                  <Checkbox
                    checked={selectedSurveys.includes(survey.id)}
                    onChange={() => handleToggleSurvey(survey.id)}
                  />
                </Grid>
              </Grid>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <SldButtons.PurpleButton
            variant="contained"
            onClick={handleSendRequest}
            disabled={selectedSurveys.length === 0}
          >
            Send Request
          </SldButtons.PurpleButton>
          <SldButtons.BlackButtonO onClick={handleCancel}>
            Cancel
          </SldButtons.BlackButtonO>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};
