import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LayOut from "src/layouts/ExecutiveHealthSurvey/executive-health-survey-start";
const Disclaimer = lazy(()=> import("src/layouts/ExecutiveHealthSurvey/components/disclaimer"))

export const ExecutiveHealthSurveyRoutes = [
    {
        path: "",
        element: (
            <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </LayOut>
        ),
        children :[
            {
               path : "ExecutiveHealthSurvey",
               index:true,
               element:<Disclaimer/> 
            }
        ]
    }
]