import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { ReactComponent as SmartLeaderWhiteLogo } from "../../icons/untitled-ui/smartLeaderLogin.svg";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeader.svg";
import Typography from "@mui/material/Typography";
import {useMediaQuery } from '@mui/material';

//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import { RECAPTCHA_KEY } from "src/config";

export const Layout = (props) => {
  const { children } = props;
  const isSmallHeight = useMediaQuery('(max-height:600px)');
  return (
    <>
  {/* <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_KEY}
    scriptProps={{
      async: false,
      defer: false,
      appendTo: "head",
      nonce: undefined,
    }}
  > */}
    <Grid container 
    sx={{ height: "100vh" }}>
      <Grid  
        item
        xs={12}
        md={4}
        sx={{ p: { md: 3, xs: 3, lg: 3, sm:3},
        height:isSmallHeight
        ? '400px' // Adjust height for small devices
        : {
            xs: '55vh', // Small screens
            sm: '40vh', // Medium screens
            md: 'auto', // Larger screens
            lg: '100%', // Extra-large screens
          },
      }}
      >
        <Stack sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
          <SmartLeaderLogo />
        </Stack>
        <Stack sx={{p: { md: 5, xs: 2, lg: 6} }}>{children}</Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          backgroundImage: "url(./assets/backgroundsSvg/loginBg.svg)",
          backgroundSize: "cover",
          height:isSmallHeight
        ? '400px' // Adjust height for small devices
        : {
            xs: '45vh', // Small screens
            sm: '60vh', // Medium screens
            md: 'auto', // Larger screens
            lg: '100%', // Extra-large screens
          },
          borderRadius: "100px 0 0 100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack justifyContent="center" 
        alignItems="center" 
        direction="column">
          <SmartLeaderWhiteLogo />
          <Typography fontSize="24px" 
          color="white"　
          sx={{ m: 2, textAlign:"center" }}>
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              Develop
            </span>{" "}
            leaders,
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              &nbsp;build
            </span>{" "}
            high performing teams,{" "}
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>create</span>{" "}
            empowering work experiences
          </Typography>
        </Stack>
      </Grid>
    </Grid>
   { /* </GoogleReCaptchaProvider> */}
   </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};