/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-07 10:38:02
 */
import axios from "axios";
import { Url } from "src/config";
import { CmsUrl } from "src/config";
import { bearerToken } from "src/config";

const CoachesCornerApiServices = {
  getOrgResults: async (accessToken, userID, sortOrder, searchValues) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getactiveorganisations?id=${userID}&search=${searchValues}&sortOrder=${sortOrder}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  sentFilterOrgResults: async (accessToken, userID, filteredOrg) => {
    console.log("filterOrg API", filteredOrg);
    try {
      let apiUrl = `${Url}/customer/coachescorner/postorgstatus?userId=${userID}`;
      const response = await axios.post(apiUrl, filteredOrg, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  sentFilterProgResults: async (accessToken, userID, filteredProg) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/postpgmstatus?userId=${userID}`;
      const response = await axios.post(apiUrl, filteredProg, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  sentFilterTeamResults: async (accessToken, userID, filteredTeam) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/postteamstatus?userId=${userID}`;
      const response = await axios.post(apiUrl, filteredTeam, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  sentFilterSyndicateResults: async (accessToken, userID, filteredSynd) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/postsyndstatus?userId=${userID}`;
      const response = await axios.post(apiUrl, filteredSynd, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  setFilterOrgResults: async (accessToken, userID) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getorganisations?id=${userID}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getProgrammes: async (accessToken) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLProgrammes`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getActiveProgrammes: async (accessToken, sortOrder, search) => {
    try {
      const body = [];
      let apiUrl = `${Url}/customer/coachescorner/getactiveprogrammes?search=${search}&sortOrder=${sortOrder}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  listFavProgrammes: async (accessToken, fav) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLProgrammes?fav=${fav}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getSyndicate: async (accessToken, programmeId, search, sortOrder) => {
    try {
      const body = [];
      let apiUrl = `${Url}/customer/coachescorner/getactivesyndicates?programmeId=${programmeId}&search=${search}&sortOrder=${sortOrder}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getAllSyndicate: async (accessToken, programmeId) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLSyndicates?programmeId=${programmeId}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  listFavSyndicate: async (accessToken, programmeId, fav) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLSyndicates?programmeId=${programmeId}&fav=${fav}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getProgrammeMembers: async (accessToken, categoryId, search, sortOrder) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLSyndicateMembers?categoryId=${categoryId}&search=${search}&sortOrder=${sortOrder}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  listfavProgrammeMembers: async (accessToken, categoryId, fav) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachISLSyndicateMembers?categoryId=${categoryId}&fav=${fav}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  ListFavOrg: async (accessToken, userID, isFav) => {
    console.log("favisting", accessToken, userID, isFav);
    try {
      let apiUrl = `${Url}/customer/coachescorner/getorganisations?id=${userID}&fav=${isFav}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  ListFavTeam: async (accessToken, tenantId, isFav, customerid) => {
    console.log("listFavTeam", accessToken, tenantId, isFav);
    try {
      const body = { customerid: customerid };
      let apiUrl = `${Url}/customer/coachescorner/getteams?tenantId=${tenantId}&fav=${isFav}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  ListFavMember: async (accessToken, categoryId, tenantId, isFav) => {
    try {
      const body = { teamId: categoryId };
      let apiUrl = `${Url}/customer/coachescorner/getmembers?tenantId=${tenantId}&fav=${isFav}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getTeams: async (
    accessToken,
    tenantId,
    customerid,
    sortOrder,
    searchValues
  ) => {
    try {
      console.log("acces token verify", accessToken);
      console.log("search and Sort", sortOrder, searchValues);
      const body = { customerid: customerid };
      let apiUrl = `${Url}/customer/coachescorner/getactiveteams?tenantId=${tenantId}&sortOrder=${sortOrder}&search=${searchValues}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getAllTeams: async (accessToken, tenantId, customerid) => {
    try {
      const body = { customerid: customerid };
      let apiUrl = `${Url}/customer/coachescorner/getteams?tenantId=${tenantId}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getUUID: async (accessToken, tenantId, customerid, dashId) => {
    try {
      console.log("acces token verify", accessToken);
      let apiUrl = `${Url}/customer/createloginuuid?userId=${customerid}&dashboadId=${dashId}&tenant_id=${tenantId}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getDetails: async (uuid) => {
    try {
      let apiUrl = `${Url}/customer/getusercre?uuid="${uuid}"`;
      const response = await axios.get(apiUrl);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  postCredentials: async (accessToken, body) => {
    try {
      console.log("acces token verify", accessToken);
      let apiUrl = `${Url}/customer/validatelogin`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  gettoken: async (accessToken, body) => {
    try {
      let apiUrl = `${Url}/customer/validateisllogin`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      sessionStorage.setItem("accessToken", accessToken);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getAdmintoken: async (accessToken, body) => {
    try {
      let apiUrl = `${Url}/customer/validateisladminlogin`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      sessionStorage.setItem("accessToken", accessToken);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMembers: async (
    accessToken,
    tenantId,
    categoryId,
    sortOrder,
    searchValues
  ) => {
    try {
      console.log("acces token verify", accessToken);
      const body = { teamId: categoryId };
      let apiUrl = `${Url}/customer/coachescorner/getmembers?tenantId=${tenantId}&sortOrder=${sortOrder}&search=${searchValues}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  sendCoachingNotes: async (accessToken, data, exeInviteId, tenantId) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/addcoachingnotes?inviteId=${exeInviteId}&tenantId=${tenantId}`;
      const response = await axios.post(apiUrl, data, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  fetchCaochesNotes: async (accessToken, userId, tenantId, coachUserId) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getcoachesnotes?userId=${userId}&tenantId=${tenantId}&coachUserId=${coachUserId}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  dashBoardResults: async (accessToken, tenantId, userId, categoryId) => {
    try {
      const body = {
        userId: userId,
        teamId: categoryId,
      };
      let apiUrl = `${Url}/customer/coachescorner/getUsersResult?tenantId=${tenantId}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  MemberWiredUpResult: async (accessToken, tenantId, userId) => {
    try {
      const body = {
        userId: userId,
      };
      let apiUrl = `${Url}/customer/coachescorner/getMembersWiredUpResult?tenantId=${tenantId}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  MemberWorkWithResult: async (accessToken, tenantId, userId) => {
    try {
      const body = {
        userId: userId,
      };
      let apiUrl = `${Url}/customer/coachescorner/getMembersWorkWithResult?tenantId=${tenantId}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      console.log("api", response);
      return response.data;
    } catch (error) {
      return error;
    }
  },
  TipsFromColleagues: async (accessToken, qnId, inviteId, tenantId) => {
    try {
      const body = {
        id: [qnId],
      };
      let apiUrl = `${Url}/customer/coachescorner/getleadershipTipsQuestions?tenantId=${tenantId}&inviteId=${inviteId}`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  TipsFromColleaguesCategoryBased: async (accessToken) => {
    try {
      let apiUrl = `${Url}/customer/coachescorner/getMyLeadershipCategory`;
      const response = await axios.get(apiUrl, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addOrgToFavourite: async (
    accessToken,
    coachUserId,
    favType,
    customerId,
    tenantId,
    isFav
  ) => {
    try {
      const body = {
        coach_id: coachUserId,
        fav_type: favType,
        org_id: customerId,
        customer_id: customerId,
        tenantId: tenantId,
        status: isFav,
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFav`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addProgrammeToFavourite: async (
    accessToken,
    coachUserId,
    favType,
    programme_id,
    tenantId,
    isFav
  ) => {
    try {
      const body = {
        coach_id: coachUserId,
        fav_type: favType,
        programme_id: programme_id,
        tenantId: tenantId,
        status: isFav,
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFavISL`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addSyndicatesToFavourite: async (
    accessToken,
    coachUserId,
    favTypeSyndi,
    programmeId,
    tenantId,
    syndicate_id,
    isFav
  ) => {
    try {
      const body = {
        coach_id: coachUserId,
        fav_type: favTypeSyndi,
        programme_id: programmeId,
        tenantId: tenantId,
        syndicate_id: syndicate_id,
        status: isFav,
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFavISL`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addMembersToFavourite: async (
    accessToken,
    coachUserId,
    favType,
    programmeId,
    tenantId,
    syndicateId,
    Id,
    isFav
  ) => {
    try {
      const body = {
        coach_id: coachUserId,
        fav_type: favType,
        programme_id: programmeId,
        tenantId: tenantId,
        syndicate_id: syndicateId,
        member_id: Id,
        status: isFav,
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFavISL`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addTeamToFavourite: async (
    accessToken,
    coachUserId,
    favType,
    customerid,
    tenantId,
    isFavValue,
    teamId
  ) => {
    try {
      console.log(
        "add team fav",
        accessToken,
        coachUserId,
        favType,
        customerid,
        tenantId,
        isFavValue,
        teamId
      );
      const body = {
        coach_id: coachUserId,
        fav_type: favType,
        org_id: customerid,
        customer_id: customerid,
        tenantId: tenantId,
        status: isFavValue,
        team_id: teamId,
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFav`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  addMemberToFavourite: async (
    accessToken,
    coachUserId,
    favType,
    customerid,
    tenantId,
    isFavValue,
    teamId,
    memberId
  ) => {
    try {
      const body = {
        coach_id: parseInt(coachUserId),
        fav_type: favType,
        org_id: parseInt(customerid),
        customer_id: parseInt(customerid),
        tenantId: parseInt(tenantId),
        status: isFavValue,
        team_id: parseInt(teamId),
        member_id: parseInt(memberId),
      };
      let apiUrl = `${Url}/customer/coachescorner/addMyFav`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  TipsFromColleaguesCategoryBasedResponse: async (
    accessToken,
    inviteId,
    tenantId,
    selectedId
  ) => {
    const body = {
      id: selectedId,
      invite_id: inviteId,
      tenantId: tenantId,
    };
    console.log(
      `accessToken: ${accessToken}, inviteId: ${inviteId}, tenantId: ${tenantId}, selectedId: ${selectedId}`
    );

    try {
      let apiUrl = `${Url}/customer/coachescorner/getMyLeadershipCategoryData`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  ViewMojoAnswers: async (
    accessToken,
    tenantId,
    surveyIdMojo,
    inviteIdMojo
  ) => {
    const body = {
      inviteId: inviteIdMojo,
      tenantId: tenantId,
      surveyId: surveyIdMojo,
    };
    try {
      let apiUrl = `${Url}/customer/coachescorner/questionResponse`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
  ViewResillienceAnswers: async (
    accessToken,
    tenantId,
    categoryId,
    surveyIdResillience,
    inviteIdResillience
  ) => {
    const body = {
      inviteId: inviteIdResillience,
      tenantId: tenantId,
      surveyId: surveyIdResillience,
      categoryId: categoryId,
    };

    console.log("categogryselected", categoryId);
    try {
      let apiUrl = `${Url}/customer/coachescorner/questionResponse/resilience `;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getHowToCoachStrapi: async (charCode) => {
    try {
      const response = await axios.get(
        `${CmsUrl}/tips-for-coachings?filters%5BMBTI_type%5D=${charCode}
        `,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "Content-Type": "application/json", // You can add other headers as needed
          },
        }
      );
      return response.data;
    } catch (error) {}
  },

  viewWiredUpAnswer: async (wiredUpInviteId, tenantId, accessToken) => {
    const body = {
      inviteId: wiredUpInviteId,
      tenantId: tenantId,
    };
    console.log("service---", body);

    try {
      let apiUrl = `${Url}/customer/coachescorner/questionResponse`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },

  viewWorkWithAnswer: async (workWithInviteId, tenantId, accessToken) => {
    const body = {
      inviteId: workWithInviteId,
      tenantId: tenantId,
    };
    console.log("service---", body);
    try {
      let apiUrl = `${Url}/customer/coachescorner/questionResponse`;
      const response = await axios.post(apiUrl, body, {
        headers: {
          "access-token": accessToken,
        },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default CoachesCornerApiServices;
