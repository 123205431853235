/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-23 22:24:04
 */
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { MTPLayOut } from "src/layouts/MTP Dashboard";
import { TeamProvider } from "src/contexts/mtp-team-context";
import { DurationProvider } from "src/contexts/mtp-duration-context";

const MtpPage = lazy(() => import("src/pages/mtp/mtPage"));
const MtpSettingsTeamSelect = lazy(() =>
  import("src/pages/mtpSettings/selectTeamAccessSettings")
);
const MtpSettings = lazy(() => import("src/pages/mtpSettings/mtpSettings"));
const HRAnalytics = lazy(() => import("src/pages/hrAnalytics/hrAnalyticsPage"));
const OrgNarratives = lazy(() => import("src/pages/organiationNarratives/orgNarratives"));
export const MTPRoutes = [
  {
    element: (
      <AuthGuard>
        <DurationProvider>
          <TeamProvider>
            <MTPLayOut>
              <Suspense>
                <Outlet />
              </Suspense>
            </MTPLayOut>
          </TeamProvider>
        </DurationProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: "MTP",
        index: true,
        element: <MtpPage />,
      },
      {
        path: "mtpSettingsTeamSelect",
        index: true,
        element: <MtpSettingsTeamSelect />,
      },
      {
        path: "mtpSettings",
        index: true,
        element: <MtpSettings />,
      },
      {
        path: "hrAnalytics",
        index: true,
        element: <HRAnalytics />,
      },
      {
        path: "mtporgnarrative",
        index: true,
        element: <OrgNarratives />,
      },
    ],
  },
];
