/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-05-06 12:27:12
 */
import axios from "axios";
import { CmsUrl, Url, bearerToken } from "src/config";

const MTPApiService = {
 getOrganization : async (accessToken) => {
    try {
        const response = await axios.get(`${Url}/customer/organization`,{headers:{
            "access-token": accessToken,
        },}) 
        return response.data;
    } catch (error) {
        return error;
    }
 },
 getTree : async (accessToken) => {
    try {
        const response = await axios.get(`${Url}/customer/gettree`,{headers:{
            "access-token": accessToken,
        },}) 
        return response.data;
    } catch (error) {
        return error;
    }
 },
 getDivsionTeams : async (accessToken,id) => {
    try {
        const response = await axios.get(`${Url}/customer/getDivisionTeams?id=${id}`,{headers:{
            "access-token": accessToken,
        },})
        return response.data
    } catch (error) {
     return error;   
    }
 },
 getTeams : async (accessToken,id) => {
    try {
        const response = await axios.get(`${Url}/customer/getTeams?divisionId=${id}`,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        
    }
 },
 getTeamMembers : async (accessToken,ids) => {
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/getuserAccess`,ids,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }
 },
 getVotQuestions : async (accessToken,mtpId,teamids) => {
    try {
       let body ={
        teamids
       }
        const response = await axios.post(`${Url}/customer/mtpsettings/getvotQuestions?mtpId=${mtpId}`,body,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }
 },
 createMTPSetting : async (accessToken,ids,userId) => {
    try {
        const response = await axios.post(`${Url}/customer/createMTPSetting?userId=${userId}`,ids,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }
 },
 votQuestionMTPSetting : async (accessToken,questionIds,mtpId) => {
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/votQuestionMTPSetting?mtpId=${mtpId}`,questionIds,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }
 },
createValuesQuestions : async (accessToken,valuesQuestions) => {

        try {
            const response = await axios.post(`${Url}/customer/mtpsettings/createValueQuestions`,valuesQuestions,{headers:{
                "access-token": accessToken,
            },})
            return response.data 
        } catch (error) {
            return error
        }
 
},
getValuesQuestions : async (accessToken,questionIds) => {

    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/getValueQuestions`,questionIds,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }

},
createPulse : async (accessToken,body) => {

    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/createPulse`,body,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }

},
createPermission : async (accessToken,body) => {

    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/usersAccessCreate`,body,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }

},

addNewMember : async (accessToken,body,teamId) => {

    try {
        console.log('---body in add new member api---',teamId);
        
        const response = await axios.post(`${Url}/customer/mtpsettings/addMember?teamId=${teamId}`,body,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }

},
getPulse : async (accessToken,body) => {

    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/getPulse`,body,{headers:{
            "access-token": accessToken,
        },})
        return response.data 
    } catch (error) {
        return error
    }

},

getUser : async (accessToken,userId) => {

    try {
        console.log('--get user api --');
        console.log('acc',accessToken);
        console.log('id',userId);
        const response = await axios.get(`${Url}/customer/mtpsettings/getUser?userId=${userId}`,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }

},

editUser : async (accessToken,editData,userId) => {

    try {
        console.log('--edit user api --');
        console.log('acc',accessToken);
        console.log('id',userId);
        const response = await axios.put(`${Url}/customer/mtpsettings/edituser?userId=${userId}`,editData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }

},

deletePulse : async (accessToken,deleteData,pulseId) => {

    try {
        console.log('--edit delete api --');
       
        const response = await axios.put(`${Url}/customer/mtpsettings/deletePulse?pulseId=${pulseId}`,deleteData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }

},
deletMember : async (accessToken,deleteData) => {
    console.log('--delete memeber api --');
       
        const response = await axios.put(`${Url}/customer/mtpSettings/deleteUser`,deleteData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
},
getCurrentPulse : async (accessToken,pulseData) => {
    console.log('--get current pulse api --');
       
        const response = await axios.post(`${Url}/customer/mtpSettings/currentPulse`,pulseData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
},
skipPulse : async (accessToken,body) => {
    try {
        console.log('--get current pulse api --'); 
        const response = await axios.post(`${Url}/customer/mtpSettings/skipPulse`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }
   
    
}
,
validateSteps : async (accessToken,body) => {
    try {
        console.log('--get current pulse api --'); 
        const response = await axios.post(`${Url}/customer/mtpSettings/validatingSteps`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }
   
    
},
editPulseSettings : async (accessToken,body) => {
    try {
        console.log('--get edit pulse api --'); 
        const response = await axios.put(`${Url}/customer/mtpSettings/editPulse`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }
},
    addUserAnonymously : async (accessToken,body) => {
        try {
            console.log('--get edit pulse api --'); 
            const response = await axios.post(`${Url}/customer/mtpSettings/ISL/addMembers`,body,{headers:{
                "access-token": accessToken
            },})
            return response.data 
        } catch (error) {
            return error
        }
   
    
},
getAllAnonymouslyInvitedUsers : async (userId,accessToken,body,membersettingsid) => {
    try {
        console.log('--get users api --'); 
        console.log(membersettingsid);
        let condition = ''
        if(membersettingsid){
            condition = `&membersettingsId=${membersettingsid}`
        }
        const response = await axios.post(`${Url}/customer/mtpSettings/ISL/getInvitedUsers?userId=${userId}${condition}`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }


},
editAnonymousUser : async (upDateData,accessToken,membersettingsid) => {
    try {
        
        const response = await axios.put(`${Url}/customer/mtpSettings/ISL/getInvitedUsers?membersettingsId=${membersettingsid}`,upDateData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }


}

,
createMTP_PUlse_ISL : async (accessToken,pulseData) => {
    try {
        
        const response = await axios.post(`${Url}/customer/mtpSettings/ISL/createPulse`,pulseData,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }


},
DownloadLinks : async (accessToken,body) => {
    try {
        
        const response = await axios.post(`${Url}/customer/mtpsettings/downloadSurveyLinks`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data 
    } catch (error) {
        return error
    }


},
getInvitedUsers : async (accessToken,inviteId)=>{
    try {
        const response = await axios.get(`${Url}/customer/mtpsettings/getSurveyLinks?inviteId=${inviteId}`,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
},
reOpenLink : async (accessToken,body)=>{
    try {
        const response = await axios.put(`${Url}/customer/mtpsettings/updateSurveyLinkStatus`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
}   ,
deleteLink : async (accessToken,body)=>{
    try {
        const response = await axios.put(`${Url}/customer/mtpsettings/deleteSurveyLink`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
}  ,
getnoninvitedUsers : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/getNotInvitedUsers`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
} ,
inviteNewUser : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/inviteNewMember`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
},
closePulse : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/closePulse`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        
    }
},
undoValuesQuestionChanges : async (accessToken,body)=>{
    try {
        const response = await axios.put(`${Url}/customer/mtpsettings/valuesQuestions/undochanges`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        return error
    }
},
reopenPulse  : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/reopenPulse`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        return error
    }
},
changePublishStatus  : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/changePublishstatus`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        return error
    }
},
checkPermission  : async (accessToken,teamId,isAdmin)=>{
    try {
        console.log('---inside check permission api---');
        
        const body ={
            teamId,
            isAdmin
        }
        const response = await axios.post(`${Url}/customer/mtpsettings/checkPermission`,body,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        return error
    }
},
getTeamsHavingPermission  : async (accessToken)=>{
    try {
        
        const response = await axios.get(`${Url}/customer/mtpsettings/getTeamsHavingPermission`,{headers:{
            "access-token": accessToken
        },})
        return response.data  
    } catch (error) {
        return error
    }
},
getCultureImageDetails : async()=>{
    try {
        const response = await axios.get(`${CmsUrl}/mtp-culture-contents`,{headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json', // You can add other headers as needed
          },});
          return response.data
    } catch (error) {
        return error;
    }
},
resendInvitation  : async (accessToken,body)=>{
    try {
        const response = await axios.post(`${Url}/customer/mtpsettings/resendInvitation`,body,{headers:{
            "access-token": accessToken
        },}) 
        return response.data  
    } catch (error) {
        return error
    }
},
getTeamDetails  : async (accessToken,body)=>{
    try {
        console.log('---inside get team details api---',body);
        const response = await axios.post(`${Url}/customer/mtpsettings/getTeamDetails`,body,{headers:{
            "access-token": accessToken
        },}) 
        return response.data  
    } catch (error) {
        return error
    }
},
getPulseArchieveData  : async (accessToken,body)=>{
    try {
        console.log('---inside get team details api---',body);
        const response = await axios.post(`${Url}/customer/mtpsettings/getArchive`,body,{headers:{
            "access-token": accessToken
        },}) 
        return response.data  
    } catch (error) {
        return error
    }
},

sendResult  : async (accessToken,body)=>{
    try {
        console.log('---inside send result manually api---',body);
        const response = await axios.post(`${Url}/customer/mtpsettings/sendManualResult`,body,{headers:{
            "access-token": accessToken
        },}) 
        return response.data  
    } catch (error) {
        return error
    }
},
deleteISLInvitedUser  : async (accessToken,body)=>{
    try {
        console.log('---inside send result manually api---',body);
        const response = await axios.put(`${Url}/customer/mtpSettings/ISL/deleteUser`,body,{headers:{
            "access-token": accessToken
        },}) 
        return response.data  
    } catch (error) {
        return error
    }
},


}
export default MTPApiService