import axios from "axios";
import { Url } from "src/config";

const executiveHealthResultsApi = {
  /**
   * API function  to get results of executive health survey
   * @param {string} accessToken
   * @returns
   */
  getHealthSurveyResults: async (accessToken) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getExecutiveHealth`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  /**
   * API function to get data of each category of executive health survey
   * @param {string} accessToken
   * @param {number} categoryId
   * @returns
   */
  getCategoryData: async (accessToken, categoryId,inviteid) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/executiveHealthCategory?category_id=${categoryId}&inviteId=${inviteid}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  /**
   * API to get BMI data
   * @param {string} accessToken
   * @returns
   */
  getBMIData: async (accessToken) => {
    console.log("inside get bmi data", accessToken);
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/executivehealth/bmiResults`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },

  getBMIDataCoachesCorner: async (accessToken, userId, tenantId) => {
    console.log("inside get bmi data", accessToken);
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/executivehealth/bmiResultsforcoach?tenantId=${tenantId}&userId=${userId}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
};

export default executiveHealthResultsApi;
