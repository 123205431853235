import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LayOut from "src/layouts/MyEmotionalResilienceSurvey/EmotionalResilienceStart";
const Disclaimer = lazy(()=> import("src/layouts/MyEmotionalResilienceSurvey/EmotionalResilienceComponents/disclaimer"));

export const myEmotionalResilenceSurveyRoutes = [
   {
    path: "",
        element: (
            <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </LayOut>
        ),
        children :[
           {
            path :"MyEmotionalResilienceSurvey",
            index:true,
            element:<Disclaimer/>
           }
        ]
   }
]