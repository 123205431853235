/**
 * @Author: Anandp
 * @Date:   2024-07-31 23:10:56
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-09-24 12:22:11
 */
import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
// joyride
import Joyride, { STATUS } from 'react-joyride';
import React, { useState, useEffect } from 'react';
import CustomTooltip from 'src/components/CustomTooltipJoyridenew';
// joyride
import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTypography } from "src/theme/base/customTypography";

import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router";
import loginApiService from "src/services/loginVerifyAPI";
import { useUser } from "src/hooks/use-user";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
// joyride
import 'src/components/joyride.css';
import customerApiService from "src/services/customerApi";
import CryptoJS from 'crypto-js';
const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

export const TopNav = (props) => {
  const typography = createTypography();
  const { onMobileNavOpen, ...other } = props;
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { isMinimized } = useSidebar();
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  const getPermissions = async () => {
    await customerApiService.getJoyride(accessToken, user.id);
    await loginApiService.getDashboardIds(accessToken, user.id);
    await loginApiService.getSurveyIds(accessToken, user.id);

  };
  // eslint-disable-next-line
  /*   const updateFinish = async () => {
      try {
        const body = {
          "dashboard": 1
        }
        let data = await customerApiService.updateJoyride(accessToken, body);
        if (data) {
          await customerApiService.getJoyride(accessToken, user.id);
        }
      } catch (error) {
        console.log(error.message);
      }
    } */
  const currentHref = window.location.pathname;
  //const [isFixedClassAdded, setIsFixedClassAdded] = useState(false);

  // joyride
  const [run, setRun] = useState(false);
  // eslint-disable-next-line
  const [stepIndex, setStepIndex] = useState(0);
  const [disableScroll, setDisableScroll] = useState(false);
  const [steps, setSteps] = useState([
    {
      content: (
        <div>
          <NavigateStart />
          <h2>Welcome to your new SmartLeader dashboard</h2>
          <p>Take the tour to discover new features and how to access your personal and team data</p>
        </div>
      ),
      locale: { next: 'Lets Go' },
      placement: 'center',
      target: 'body',
    },
    {
      content: 'From the dashboard menu you can select the dashboard you would like to access',
      placement: 'top',
      target: '.joyride-target-1',

    },
    {
      content: 'Select from the options to view the top current or aspired team cultures​',
      target: '.joyride-target-2',
      placement: 'left',
      options: {
        scrollOffset: 20, // Set scroll offset for this step
      },
      styles: {
        options: {
          width: 300,
        },
      },
    },
    {
      content: (
        <div>
          Choose from the options to view trends over various time periods
          <br />
        </div>
      ),
      placement: 'right',
      target: '.joyride-target-3',
    },
    {
      content: (
        <div>
          Go to the explore page to view additional resources and keep up to date with system updates.
        </div>
      ),
      placement: 'bottom',
      target: '.joyride-target-4',
    },
    {
      content: (
        <div>
          Come back here if you would like to have another look at how to navigate through the dashboard
        </div>
      ),
      placement: 'bottom',
      target: '.joyride-target-5',
      title: 'Last',
    },
  ]);
  // useEffect(() => {
  //   const elements = document.querySelectorAll("#topNavWidth");
  //   elements.forEach(element => {
  //     if (isFixedClassAdded) {
  //       element.classList.add("Rfix");
  //     } else {
  //       element.classList.remove("Rfix");
  //     }
  //   });
  // }, [isFixedClassAdded]);
  // eslint-disable-next-line 
  const handleClickStart = (event, callback) => {
    event.preventDefault();

    // Scroll to the top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Delay the filtering and setting of steps
    setTimeout(() => {
      const filteredSteps = steps.filter((step) => document.querySelector(step.target));

      if (filteredSteps.length > 0) {
        setSteps(filteredSteps);
        setRun(true);
      } else {
        console.warn('No valid targets found for Joyride steps.');
      }

      // Invoke the callback if provided
      if (callback && typeof callback === 'function') {
        callback();
      }
    }, 500);
  };
  const userDetails = sessionStorage.getItem("userDetails");
  useEffect(() => {
    const joyrideStatusEn = sessionStorage.getItem("joyrideStatus");
    const joyrideStatus = CryptoJS.AES.decrypt(joyrideStatusEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
    const htw = JSON.parse(joyrideStatus).dashboard === 1;
    console.log('htw:', htw,JSON.parse(userDetails).userConsent);
    // updateFinish();
    if ((JSON.parse(userDetails).userConsent && JSON.parse(userDetails).userConsent === true) && currentHref !=="/profileswitch" && htw === false) {
      console.log('Triggering Joyride...');
      handleClickStart(new Event('click'));
      console.log('Started Joyride...');
    }
    console.log('Started Joyride...');
    // eslint-disable-next-line
  }, [userDetails,currentHref]);
  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }

    setStepIndex(index);

    // Disable scrolling for a particular step (example: step 2)
    if (index === 0 || index === 4 || index === 5) {
      setDisableScroll(true);
    } else {
      setDisableScroll(false);
    }
  };

  return (
    <Box
      component="header"
      id="topNavWidth"
      className={currentHref && currentHref === '/profileswitch' ? 'profileswitch' : ''}
      sx={{
        '&.profileswitch': { display: 'none' },
        backdropFilter: "blur(6px)",
        paddingRight: '16px',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        transition: 'width 0.3s ease, left 0.3s ease',
        '&.Rfix': { position: 'relative' },
        left: {
          lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          marginBottom: 1.5,
        }}
      >
        <Stack alignItems="center"
          direction="row"
          spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {// eslint-disable-next-line
            currentHref && currentHref != "/profile" &&
            <Typography pl={{ lg: '20px', md: '20px', sm: "10px", xs: '10px' }}
              ml={{ lg: '0px', md: '0px', sm: "0px", xs: '0px' }}

              sx={typography.profileHeaing}
              onClick={getPermissions}
             >SmartLeader Sector Trends
              </Typography>
          }
          <SearchButton />
        </Stack>

        <Stack alignItems="center"
          direction="row"
          spacing={2}>
          {/* joyride */}
          <IconButton id="downloadButton1"
            onClick={handleClickStart}
            sx={{marginRight:'-20px!important'}}
            >
            <HelpOutlineIcon className='joyride-target-5' />
          </IconButton>

          <>
            <Joyride

              callback={handleJoyrideCallback}
              continuous
              run={run}
              scrollToFirstStep
              disableOverlayClose={true}
              disableCloseOnEsc={true}
              showProgress
              showSkipButton
              scrollOffset={350}
              steps={steps}
              disableScrolling={disableScroll}
              tooltipComponent={CustomTooltip}
              disableScrollParentFix={true}
              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
            />
          </>
          <IconButton id="downloadButton2"
            onClick={navigateToExplore}
            sx={{margin:'0!important'}}
            >
              
            <LanguageIcon className='joyride-target-4' />
          </IconButton>
          <AccountButton  />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
