/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-11-30 00:01:37
 */
import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import { Scrollbar } from "src/components/scrollbar";
import { usePathname } from "src/hooks/use-pathname";
import { SideNavSection } from "./side-nav-section";
import IconButton from "@mui/material/IconButton";
import CryptoJS from "crypto-js";

import { ReactComponent as SmartLeader } from "../../../icons/untitled-ui/navSmartLeader.svg";
import Typography from "@mui/material/Typography";
import { createTypography } from "src/theme/base/customTypography";
import { ReactComponent as BluePuzzle } from "../../../icons/untitled-ui/puzzleBlue.svg";
import { ReactComponent as GreenPuzzle } from "../../../icons/untitled-ui/puzzleGreenp.svg";
import { ReactComponent as RedPuzzle } from "../../../icons/untitled-ui/puzzleRed.svg";
import { ReactComponent as YellowPuzzle } from "../../../icons/untitled-ui/puzzleYellow.svg";
import surveyAPIService from "src/services/surveyApi";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import { useAuth } from 'src/hooks/use-auth';
import { useUser } from "src/hooks/use-user";
// import mediaUploadApis from "src/services/mediaUploadApi";
import Avatar from '@mui/material/Avatar';
import DashboardMenu from "src/components/dashboard-menu";

import { useSidebar } from "src/contexts/SidebarContext";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      case "blend-in":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.background.default,
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.neutral[100],
            "--nav-logo-border": theme.palette.neutral[100],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.text.secondary,
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "discreet":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[900],
            "--nav-color": theme.palette.neutral[100],
            "--nav-border-color": theme.palette.neutral[700],
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[600],
            "--nav-item-icon-color": theme.palette.neutral[500],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[700],
            "--nav-item-chevron-color": theme.palette.neutral[700],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[50],
            "--nav-color": theme.palette.text.primary,
            "--nav-border-color": theme.palette.divider,
            "--nav-logo-border": theme.palette.neutral[200],
            "--nav-section-title-color": theme.palette.neutral[500],
            "--nav-item-color": theme.palette.neutral[500],
            "--nav-item-hover-bg": theme.palette.action.hover,
            "--nav-item-active-bg": theme.palette.action.selected,
            "--nav-item-active-color": theme.palette.text.primary,
            "--nav-item-disabled-color": theme.palette.neutral[400],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[400],
            "--nav-item-chevron-color": theme.palette.neutral[400],
            "--nav-scrollbar-color": theme.palette.neutral[900],
          };
        }

      case "evident":
        if (theme.palette.mode === "dark") {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        } else {
          return {
            "--nav-bg": theme.palette.neutral[800],
            "--nav-color": theme.palette.common.white,
            "--nav-border-color": "transparent",
            "--nav-logo-border": theme.palette.neutral[700],
            "--nav-section-title-color": theme.palette.neutral[400],
            "--nav-item-color": theme.palette.neutral[400],
            "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
            "--nav-item-active-color": theme.palette.common.white,
            "--nav-item-disabled-color": theme.palette.neutral[500],
            "--nav-item-icon-color": theme.palette.neutral[400],
            "--nav-item-icon-active-color": theme.palette.primary.main,
            "--nav-item-icon-disabled-color": theme.palette.neutral[500],
            "--nav-item-chevron-color": theme.palette.neutral[600],
            "--nav-scrollbar-color": theme.palette.neutral[400],
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const SideNav = (props) => {
  const themeTypography = createTypography();
  const { color = "evident", sections = [], updatetitle } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const { users } = useAuth();
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const { isMinimized, toggleSidebar } = useSidebar();

  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const accessToken = sessionStorage.getItem("accessToken")
  const [colorCode, setColorCode] = useState('');
  console.log("navbar", userDetails);
  console.log('sections in side nav', sections);
  const user = useUser();
  // const userId = user.id;
  console.log("useuser", user, users);
  const getMedia = async () => {
    // const response = await mediaUploadApis.getProfilePicture(
    //   accessToken,
    //   userId
    // );
    // console.log("response", response.mediaData);
    // setUploadedProfileImage(response.mediaData);
    const profilePicture = sessionStorage.getItem("profilePicture");
    console.log("response", profilePicture);
    setUploadedProfileImage(JSON.parse(profilePicture ?? null));
  };


  useEffect(() => {
    getMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * DescFunction to get wiredup survey result color code
   * @date 11/21/2023 - 12:08:15 AM
   *
   * @async
   * */
  const getWiredUpColorCode = async () => {
    try {
      const response = await surveyAPIService.getColorCode(accessToken);
      console.log('color code response', response.surveyResults[0].colorCode);
      setColorCode(response.surveyResults[0].colorCode)
    } catch (error) {

    }
  }
  useEffect(() => {
    getWiredUpColorCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const navigate = useNavigate();

  const handleNavigate = () => {

    navigate("/dashboard");
  };

  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(
    surveyPermsEn,
    user.id.toString()
  ).toString(CryptoJS.enc.Utf8);
  return (
    <Drawer
      anchor="left"
      id="drawerr"
      open
      PaperProps={{
        sx: {
          ...cssVars,

          backgroundColor: "#FFFFFF",
          borderRightColor: "var(--nav-border-color)",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          color: "var(--nav-color)",
          width: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH, // Adjust width based on minimized state          
          boxShadow: "8px 4px 24px 0px #00000014",
          borderRadius: "0 15px 15px 0",
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)",
          },
        }}
      >
        <Stack sx={{
          height: "100%",
          '& .expandicon': { display: 'none' },
          '&.minimized-sidebar .expandicon': { display: 'block', marginLeft: 0, padding: 0 },
          '&.minimized-sidebar .collapaseicon': { display: 'none', marginLeft: 0, padding: 0 },
          '&.minimized-sidebar .pp-name': { visibility: 'hidden' }


        }}
          className={isMinimized ? 'minimized-sidebar' : ''}

        >
          <Stack alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 3 }}>
            <SldSurveyCard.SmartLeaderBox
              onClick={handleNavigate}
            >
              <SmartLeader />
            </SldSurveyCard.SmartLeaderBox>
            <IconButton onClick={toggleSidebar}
              className='collapaseicon'>
              <KeyboardDoubleArrowLeftIcon />
            </IconButton>
            <IconButton onClick={toggleSidebar}
              className='expandicon'>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          </Stack>
          <Stack className="pp-name"
            spacing={2}
            sx={{
              //flexGrow: 1,
              px: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 180, // Adjust the width of the outer box as needed
                height: 180, // Adjust the height of the outer box as needed
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 150,
                  width: 150,

                }}
              >
                {uploadedProfileImage ? (
                  <img
                    src={user.avatar ? `data:image/png;base64,${uploadedProfileImage.data}` : null}
                    alt="UserImage"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Avatar style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}>
                    <img
                      src="/userImage.jpg" // Replace with the actual image URL or path
                      alt="UserImage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Adjust the image sizing to cover the inner box
                      }}
                    />
                  </Avatar>

                )}
              </Box>
            </Box>
            {
             surveyPerms && surveyPerms.includes(7) &&
              <Stack
                style={{
                  padding: 0,
                  marginTop: -70,
                  marginLeft: 125,
                  width: 55,
                  height: 55,
                  zIndex: '1'
                }}
              >
                {colorCode === "blue" && (
                  <BluePuzzle style={{ width: 55, height: 55 }} />
                )}
                {colorCode === "red" && (
                  <RedPuzzle style={{ width: 55, height: 55 }} />
                )}
                {colorCode === "yellow" && (
                  <YellowPuzzle style={{ width: 55, height: 55 }} />
                )}
                {colorCode === "green" && (
                  <GreenPuzzle style={{ width: 55, height: 55 }} />
                )}
              </Stack>
            }


            <Typography
              sx={{ ...themeTypography.userName, textAlign: "center" }}
            >
              {userDetails?.userFirstName}'s Lead Self Dashboard
            </Typography>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              // backgroundColor: "yellow",
              flexGrow: 1,
              px: 2,
            }}
          >


            <DashboardMenu />
            {/* <TenantSwitch sx={{ flexGrow: 1 }} /> */}

            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
                updatetitle={updatetitle}
              />
            ))}
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
