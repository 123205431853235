// src/utils/joyrideSteps.js
import { ReactComponent as NavigateStart } from "src/icons/untitled-ui/NavigateStart.svg";

import { ReactComponent as TootipMTp2 } from "src/icons/untitled-ui/tooltip.svg";

export const getJoyrideSteps = (pathname,tenant_id) => {
  console.log("tenant_id in joyridedb",tenant_id);
  
    switch (pathname) {
        case '/MTP':
            return [
                {
                    content: (<div>
                      <NavigateStart />
                      <h2>Walkthrough of MyTeamPulse</h2>
                      <p>Take the tour to discover new features and useful tips to learn more about your team and keep your finger on your team’s pulse.</p>
                    </div>),
                    locale: { next: 'Start' },
                    placement: 'center',
                    target: 'body',
                    title: 'Start'
                  },
                  {
                    content: 'From team selector you can choose which teams to view. You can also view the whole organisation.',
                    placement: 'top-end',
                    target: '.joyride-target-mtp1',
                  },
                  {
                    content: 'You can download the metrics displayed on page to...',
                    placement: 'left',
                    styles: {
                      options: {
                        width: 300,
                      },
                    },
                    target: '.joyride-target-2',
          
                  },
                  {
                    content: (
                      <div>
                        Choose to view results by month or quarter
                        <br />
                      </div>
                    ),
                    placement: 'bottom',
                    target: '.joyride-target-3',
          
                  },
                  {
                    content: (
                      <div>
                        Click on each tile for a detailed breakdown of results, including calculation logic and the DIY Fixit toolkit.
                      </div>
                    ),
                    placement: 'top-start',
                    target: '.joyride-target-4',
                  },
                  {
                    content: (
                      <div>
          
                        Need more details about each metric? Click the info icon to learn more
                      </div>
                    ),
                    placement: 'top-start',
                    target: '.joyride-target-5',
                  },
                  {
                    content: (
                      <div>
                        Find answers to the team's narrative questions here.
                      </div>
                    ),
                    placement: 'left',
                    target: '.joyride-target-6',
                    title: 'Last'
          
                  },
                // ... other steps
            ];
        case '/hranalytics':
            return [
                {
                    content: (<div>
                      <NavigateStart />
                      <h2>Welcome to Organisation Analytics</h2>
                      <p>Take the tour to discover how you could analyse your organisation’s MyTeamPulse results
          
                      </p>
                    </div>),
                    locale: { next: 'Lets go' },
                    placement: 'center',
                    target: 'body',
                  },
                  {
                    content: 'Click here to select which departments or teams you would like to see',
                    placement: 'top-start',
                    target: '.joyride-target-hr1',
                  },
                  {
                    content: 'Use the sort option here to select by which metric you would like the teams to be sorted by',
                    placement: 'top-start',
                    styles: {
                      options: {
                        width: 300,
                      },
                    },
                    target: '.joyride-target-hr2',
          
                  },
                  {
                    content: (
                      <div>
                        Click here to select the metric you would like to compare against the sector benchmark. You can also choose the period for your results      <br />
                      </div>
                    ),
                    placement: 'auto',
                    target: '.joyride-target-hr3',
                    title: 'Last'
          
                  },
                // ... other steps
            ];
        case '/mtpSettingsTeamSelect':
                return [
                    {
                        content: (<div>
                          <NavigateStart />
                          <h2>Welcome to MyTeamPulse Settings</h2>
                          <p>Take the tour to learn how you could customise your team’s pulse settings.</p>
                        </div>),
                        locale: { next: 'Start Tour!' },
                        placement: 'center',
                        target: 'body',
                      },
                      {
                        content: 'Click here to select the divisions or individual teams you would like to apply the settings for. Select teams to move to the next step of the tour.',
                        placement: 'right',
                        target: '.joyride-target-ms1',
                        title: 'Last'
              
                      },
                ];
        case '/mtpSettings':
          if(parseInt(tenant_id)===0 &&tenant_id!==null){

            return [

              {
                  content: 'All the pending pulses for the selected teams are visible here',
                  placement: 'right-start',
                  styles: {
                    options: {
                      width: 300,
                    },
                  },
                  target: '.main',
                  spotlightClicks: true,
                  id:'joyride-target-mts1',
                  disableBeacon:'true'
        
                },
        
        
                {
                  content: (
                    <div>
                      Use this page to add new members to the team or allocate viewing rights      <br />
                    </div>
                  ),
                  placement: 'top',
                  target: '.step1',
                  spotlightClicks: true,
                  id:'joyride-target-ms2'
        
                },
                // {
                //   content: (
                //     <div>
                //       Enter your team’s values here. These will be included in every Value pulse that is sent to the team</div>
                //   ),
                //   placement: 'right',
                //   target: '.step2',
                //   spotlightClicks: true,
                //   id:'joyride-target-ms3'
        
                // },
                {
                  content: (<div>
                    <TootipMTp2/>
                    <h2>First Lets Set up</h2>
                    <p>Before you get started with pusle frequency settings, first set up the frequency of pulse and date of next pulse.</p>
                  </div>),
                  locale: { next: 'Start Tour!' },
                  placement: 'right',
                  target: '.step3',
                  title: 'Lets go',
                  spotlightClicks: true,
                  id:'joyride-target-ms4'
        
                },
                // {
                //   content: (
                //     <div>
                //       Start by setting the frequency of the pulse. Use the dropdown here to select the frequency.</div>
                //   ),
                //   placement: 'right',
                //   target: '.joyride-target-ms5',
                //   id:'joyride-target-ms5'
                // },
                {
                  content: (
                    <div>
                      Use the calendar to select a starting date for the pulse.</div>
                  ),
                  placement: 'right',
                  target: '.joyride-target-ms6',
                  id:'joyride-target-ms6'
        
                },
                {
                  content: (
                    <div>
                      You can edit the automated schedules for the pulse reminders here. Simply, adjust the date and time for the scheduled reminders.</div>
                  ),
                  placement: 'top',
                  target: '.joyride-target-ms7',
                  id:'joyride-target-ms7',
                  title: 'Last'
                },
          ];
          }else if(tenant_id!==null){
            return [

              {
                  content: 'All the pending pulses for the selected teams are visible here',
                  placement: 'right-start',
                  styles: {
                    options: {
                      width: 300,
                    },
                  },
                  target: '.main',
                  spotlightClicks: true,
                  id:'joyride-target-mts1',
                  disableBeacon:'true'
        
                },
        
        
                {
                  content: (
                    <div>
                      Use this page to add new members to the team or allocate viewing rights      <br />
                    </div>
                  ),
                  placement: 'top',
                  target: '.step1',
                  spotlightClicks: true,
                  id:'joyride-target-ms2'
        
                },
                {
                  content: (
                    <div>
                      Enter your team’s values here. These will be included in every Value pulse that is sent to the team</div>
                  ),
                  placement: 'right',
                  target: '.step2',
                  spotlightClicks: true,
                  id:'joyride-target-ms3'
        
                },
                {
                  content: (<div>
                    <TootipMTp2/>
                    <h2>First Lets Set up</h2>
                    <p>Before you get started with pusle frequency settings, first set up the frequency of pulse and date of next pulse.</p>
                  </div>),
                  locale: { next: 'Start Tour!' },
                  placement: 'right',
                  target: '.step3',
                  title: 'Lets go',
                  spotlightClicks: true,
                  id:'joyride-target-ms4'
        
                },
                {
                  content: (
                    <div>
                      Start by setting the frequency of the pulse. Use the dropdown here to select the frequency.</div>
                  ),
                  placement: 'right',
                  target: '.joyride-target-ms5',
                  id:'joyride-target-ms5'
                },
                {
                  content: (
                    <div>
                      Use the calendar to select a starting date for the pulse.</div>
                  ),
                  placement: 'right',
                  target: '.joyride-target-ms6',
                  id:'joyride-target-ms6'
        
                },
                {
                  content: (
                    <div>
                      You can edit the automated schedules for the pulse reminders here. Simply, adjust the date and time for the scheduled reminders.</div>
                  ),
                  placement: 'top',
                  target: '.joyride-target-ms7',
                  id:'joyride-target-ms7',
                  title: 'Last'
                },
          ];
          }
          break;
              
        // Add more cases for other paths
        default:
            return [];
    }
};
