/**
 * @Author: Anandp
 * @Date:   2024-05-08 10:40:25
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-06-13 21:10:01
 */
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
// import LayOut from "src/layouts/MyEmotionalResilienceSurvey/EmotionalResilienceStart";
const CoachesCornerMyTeamPulse = lazy(()=> import("src/pages/coaches-corner/myTeamPulsePage"));

export const coachesCornerMyTeamPulseRoutes = [
   {
    path: "",
        element: (
            // <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            // </LayOut>
        ),
        children :[
           {
            path :"redirectingphase",
            index:true,
            element:<CoachesCornerMyTeamPulse/>
           }
        ]
   }
]