/**
 * @Author: Anandp
 * @Date:   2024-09-09 13:02:56
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-10-13 21:50:57
 */
import axios from "axios";
import { Url } from "src/config";

const myLeadershipApi = {
  getMyLeadershipDashboard: async (accessToken, invite_id, userId) => {
    try {
      const params = {};

      if (invite_id) {
        params.invite_id = invite_id;
      }
      if (userId) {
        params.id = userId;
      }
      const response = await axios.get(
        `${Url}/customer/dashboard/myLeadership`,
        {
          headers: {
            "access-token": accessToken,
          },
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershipDashboardUsers: async (accessToken, date, tenantId, userId) => {
    try {
      const params = {};

      if (date) {
        params.date = date;
      }

      const response = await axios.get(
        `${Url}/customer/dashboard/myLeadershipforCoach?tenant_id=${tenantId}&id=${userId}`,
        {
          headers: {
            "access-token": accessToken,
          },
          params: params,
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershStatus: async (accessToken) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getMyleadershipStatus`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) { }
  },
  getTips: async (accessToken, inviteId, questionIds,tenant_id) => {
    try {
      const response = await axios.post(
        `${Url}/customer/dashboard/getleadershipTipsQuestions?inviteId=${inviteId}&tenant_id=${tenant_id}`,
        questionIds,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  },
  getMyLeadershipCategoryData: async (
    accessToken,
    inviteId,
    categoryId,
    invitedUser
  ) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getmyLeadershipCategory?inviteId=${inviteId}&categoryId=${categoryId}&invitedUser=${invitedUser}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) { }
  },
  getLBI_SUI_Data: async (accessToken, inviteId, questionId) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/lbisuidata?questionId=${questionId}&invitedId=${inviteId}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      return response.data;
    } catch (error) { }
  },

  getTimeZone: async (accessToken, tenantId) => {
    try {
      const response = await axios.get(
        `${Url}/customer/getTimeZone`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data
    } catch (error) {
      return error
    }
  },
  getMembersScore: async (accessToken, categoryId, inviteId) => {
    try {
      const response = await axios.get(
        `${Url}/customer/dashboard/getmyleadershipmemberwiseresult?inviteId=${inviteId}&categoryId=${categoryId}`,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      return response.data
    } catch (error) {
      return error
    }
  }
};

export default myLeadershipApi;
