import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import { AccountButton } from "../account-button";
import { SearchButton } from "../search-button";
import { createTypography } from "src/theme/base/customTypography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router";

const TOP_NAV_HEIGHT = 85;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
  const typography = createTypography();
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const navigateToExplore = () => {
    navigate("/explorerPage");
  };
  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        paddingRight:'16px',
        backgroundColor: '#f9f9f9',
        position: "sticky",
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
alignItems="flex-end"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          marginBottom:1.5
        }}
      >
        <Stack alignItems="center"
        
direction="row"
spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography
            pl={{ lg: "20px", md: "20px", sm: "0px", xs: "0px" }}
            sx={typography.profileHeaing}
          >
            Explore
          </Typography>
          <SearchButton />
        </Stack>
        <Stack alignItems="center"
        
direction="row"
spacing={1}>
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
          <IconButton onClick={navigateToExplore}>
            <LanguageIcon />
          </IconButton>
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
