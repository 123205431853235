/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-09-01 21:32:18
 */
import PropTypes from "prop-types";

import { useSettings } from "src/hooks/use-settings";

import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useEffect } from "react";
import loginApiService from "src/services/loginVerifyAPI";
import { useUser } from "src/hooks/use-user";
import customerApiService from "src/services/customerApi";
import { useTeam } from "src/contexts/team-context";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
// import { useNavigate } from "react-router";

export const TeamDashboardLayout = (props) => {
  const settings = useSettings();
  const sections = useSections();
  const { selectedTeamId } = useTeam();
  console.log("props", sections);
  const user = useUser();
  const accessTokens = sessionStorage.getItem("accessToken");
  // const timer = useRef(null);
  // const navigate = useNavigate();
  // const timeout = 3600000; // 1 hour timeout
  useEffect(() => {
    async function fetchData() {
      await customerApiService.getJoyride(accessTokens, user.id);
      await mtpDashboardResultsApi.getTeamPermissions(accessTokens, selectedTeamId, user.id);
      await loginApiService.getSurveyIds(accessTokens, user.id);
      await loginApiService.getDashboardIds(accessTokens, user.id);

    }
    fetchData();
    // eslint-disable-next-line
  }, []);
  // const resetTimer = () => {
  //   if (timer.current) {
  //     clearTimeout(timer.current);
  //   }
  //   timer.current = setTimeout(logout, timeout);
  // };

  // const logout = () => {
  //   localStorage.removeItem('user');
  //   sessionStorage.removeItem('accessToken');
  //   sessionStorage.removeItem('userDetails');
  //   sessionStorage.removeItem('dashboardPerms');
  //   sessionStorage.removeItem('surveyPerms');
  //   navigate('/');
  //   alert("User have been logged out due to inactivity.");
  // };

  // useEffect(() => {
  //   const events = ['mousemove', 'mousedown', 'keypress', 'touchstart'];

  //   const handleActivity = () => {
  //     resetTimer();
  //   };

  //   events.forEach(event => {
  //     window.addEventListener(event, handleActivity);
  //   });

  //   resetTimer();

  //   return () => {
  //     events.forEach(event => {
  //       window.removeEventListener(event, handleActivity);
  //     });
  //     if (timer.current) {
  //       clearTimeout(timer.current);
  //     }
  //   };
  //   // eslint-disable-next-line
  // }, []);
  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
};

TeamDashboardLayout.propTypes = {
  children: PropTypes.node,
};
