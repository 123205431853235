import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { ProfilePictureProvider } from "src/contexts/profile-picture-upload";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));
const ProfilePage = lazy(() => import("src/pages/dashboard/account"));
const ProfileSwitch = lazy(() =>
  import("src/pages/profileSwitchPage/profileSwitchPage")
);


export const dashboardRoutes = [
  {
    path: "",
    element: (
      <AuthGuard>
        <ProfilePictureProvider>
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </ProfilePictureProvider>
        
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        index: true,
        element: <IndexPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "profileSwitch",
        element: <ProfileSwitch />,
      }
        
    

      // {
      //   path: "leadSelf",
      //   index: true,
      //   element: <LeadSelfpage/>,
      // },
      // {
      //   path: "mymojo",
      //   index: true,
      //   element: <Mojo/>,
      // },
    ],
  },
];
