import React, { createContext, useState, useCallback } from 'react';

export const JoyrideContext = createContext({
    joyrideSteps: [],
    addSteps: () => {},
    removeSteps: () => {},
    setOpenAddMembersForm: () => {},
    setOpenRequestForm:()=>{},
    openAddMembersForm: false,
    openRequestForm: false,
    run: false,
});

export const JoyrideProvider = ({ children }) => {
    const [joyrideSteps, setJoyrideSteps] = useState([]);
    const [run, setRun] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [openAddMembersForm, setOpenAddMembersForm] = useState(false);
    const [openRequestForm, setOpenRequestForm] = useState(false);
    const [currentStep, setCurrentStep] = useState(0); // Track current step

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);
    const resetJoyride = () => {
        setRun(false); // Stop Joyride
        setJoyrideSteps([]); // Clear existing steps if needed
        setCurrentStep(0); // Reset current step to 0
    };
    // Function to check if step targets are available in the DOM
    const waitForElements = useCallback((steps) => {
        return new Promise((resolve) => {
            const checkTargets = () => {
                const allTargetsLoaded = steps.every(step => document.querySelector(step.target) !== null);
                if (allTargetsLoaded) {
                    resolve(true);
                } else {
                    setTimeout(checkTargets, 500);  // Retry after a short delay
                }
            };
            checkTargets();
        });
    }, []);

    const addSteps = useCallback(async (newSteps) => {
        await waitForElements(newSteps);  // Wait for new steps' targets to be available

        setJoyrideSteps((prevSteps) => {
            // Filter out any new steps that are already present
            const updatedSteps = [...prevSteps, ...newSteps].filter((step, index, self) =>
                index === self.findIndex((t) => (
                    t.id === step.id // Assuming each step has a unique 'id' property
                ))
            );
            setRun(true);  // Start or continue the Joyride
            return updatedSteps;
        });
    }, [waitForElements]);

    const continueJoyride = () => {
        setRun(true); // Ensure Joyride continues running
    };

    const removeSteps = useCallback(() => {
        setJoyrideSteps([]);
    }, []);

    return (
        <JoyrideContext.Provider 
            value={{
                joyrideSteps,
                addSteps,
                removeSteps,
                isPopupOpen,
                openPopup,
                closePopup,
                setOpenAddMembersForm,
                openAddMembersForm,
                currentStep,
                setCurrentStep,
                continueJoyride,
                run,
                setRun,
                setOpenRequestForm,
                openRequestForm,
                resetJoyride
            }}>
            {children}
        </JoyrideContext.Provider>
    );
};
