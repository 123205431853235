import React, { createContext, useContext, useState } from "react";

const goalsAndPulseContext = createContext();

export const RowIdProvider = ({ children }) => {
  const [insertedRowId, setInsertedRowId] = useState("");

  const setRowId = (id) => {
    setInsertedRowId(id);
  };

  return (
    <goalsAndPulseContext.Provider value={{ insertedRowId, setRowId }}>
      {children}
    </goalsAndPulseContext.Provider>
  );
};

export const useInsertedRowId = () => {
    return useContext(goalsAndPulseContext);
};
