import PropTypes from "prop-types";
//import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import Divider from "@mui/material/Divider";
import { Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as SldSurveyCard from "src/theme/base/SldSurveyCard";
import myImage from "src/icons/untitled-ui/switch-logo.png";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook


const SIDE_NAV_WIDTH = 250;
const SIDE_NAV_MINIMIZED_WIDTH = 80; // Adjust the width for minimized state

const VerticalLayoutRoot = styled('div')(({ theme, navWidth }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  transition: 'padding-left 0.3s ease',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: navWidth,
  },
}));

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { isMinimized, handleNavToggle } = useSidebar();
    const mobileNav = useMobileNav();
  const navigate = useNavigate();
  const currentHref = window.location.pathname;

  const handleNavigate = () => {
    navigate("/");
  };

  

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          onToggle={(isMinimized) => handleNavToggle(isMinimized)}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <Divider />

      <VerticalLayoutRoot
        id="layoutContainer"
        className={currentHref && currentHref === "/profileswitch" ? "profileswitchlayout" : ""}
        sx={{ "&.profileswitchlayout": { paddingLeft: "0" } }}
        navWidth={isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH}
      >
        <VerticalLayoutContainer>
          <Stack direction="row">


            <Box
              direction="row"
              className={currentHref && currentHref === "/profileswitch" ? "profileswitch-show" : "profileswitch-hide"}
              sx={{
                "&.profileswitch-show": { display: "block", zIndex: "1800" },
                "&.profileswitch-hide": { display: "none" },
              }}
            >
              <SldSurveyCard.SmartLeaderBox onClick={handleNavigate}>
                <img src={myImage}
alt="Switch Logo" />
              </SldSurveyCard.SmartLeaderBox>
            </Box>
          </Stack>
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
