import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthGuard } from "src/guards/auth-guard";
import { TeamDashboardLayout } from "src/layouts/teamDashboard";
import { TeamProvider } from "src/contexts/team-context";
const LeadershipBrand = lazy(() =>
  import("src/pages/leadershipBrandSurvey/leadershipBrandSurveyPage")
);
const ClickWith = lazy(() => import("src/pages/clickWith/clickWithpage"));
const TeamCompatability = lazy(() => import("src/pages/teamCompatability/teamCompatabilityPage"));
const TeamDiversity = lazy(() => import("src/pages/teamDiversity/teamDiversityPage"));
const TeamMojo = lazy(() => import("src/pages/teamMojo/teamMojopage"));
const TeamDevelopment = lazy(() => import("src/pages/teamDevelopment/teamDevelopmentPage"));

export const TeamDashboardRoutes = [
  {
    path: "",
    element: (
      <AuthGuard>
          <TeamProvider>
           <TeamDashboardLayout>
            <Suspense>
             <Outlet />
            </Suspense>
           </TeamDashboardLayout>
          </TeamProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: "leadershipBrand",
        index: true,
        element: <LeadershipBrand />,
      },
      {
        path: "teamMojo",
        index: true,
        element: <TeamMojo />,
      },
      {
        path: "clickwith",
        index: true,
        element: <ClickWith />,
      },
      {
        path: "teamCompatability",
        index: true,
        element: <TeamCompatability />,
      },
      {
        path: "teamDiversity",
        index: true,
        element: <TeamDiversity />,
      },
      {
        path: "teamDevelopment",
        index: true,
        element: <TeamDevelopment />,
      },
    ],
  },
];
