// import path from "path";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LayOut from "src/layouts/myMojoSurvey/myMojoStartPageLayout";
const Disclaimer = lazy(()=> import("src/layouts/myMojoSurvey/myMojoComponentss/disclaimer"));

export const myMojoSurveyRoutes = [
    {
        path: "",
        element: (
            <LayOut>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </LayOut>
        ),
        children :[
           { path:"myMojoSurvey",
            index:true,
            element: <Disclaimer/>}
        ]
    }
]