/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:41:01
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-11-30 00:00:43
 */

import Box from "@mui/material/Box";
import Avatar from '@mui/material/Avatar';

import { useEffect, useState } from "react";
import { useAuth } from 'src/hooks/use-auth';
import { useUser } from "src/hooks/use-user";
// import mediaUploadApis from "src/services/mediaUploadApi";
import Typography from "@mui/material/Typography";
import { useProfilePicture } from "src/contexts/profile-picture-upload";

export const AccountIcon = (props) => {
  const { users } = useAuth();
  const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  // const accessToken = sessionStorage.getItem("accessToken");
  const user = useUser();
  // const userId = user.id;
  console.log("useuser", user, users);
  const { profilePictureUploaded } = useProfilePicture();


  const getMedia = async () => {
    // const response = await mediaUploadApis.getProfilePicture(
    //   accessToken,
    //   userId
    // );
    const profilePicture = sessionStorage.getItem("profilePicture");
    console.log("response", profilePicture);
    setUploadedProfileImage(JSON.parse(profilePicture ?? null));
  };


  useEffect(() => {
    getMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePictureUploaded]);



  return (
    <Box
      sx={{ width: 'auto', display: 'flex', padding: '0 0px 0 0', alignItems: 'center', }}
    >
      <Avatar
        sx={{
          height: 40,
          width: 40,
          border: '1px solid #535B66'
        }}

      >
        {uploadedProfileImage ? (
          <img
            src={user.avatar ? `data:image/png;base64,${uploadedProfileImage.data}` : null}
            alt="UserImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (

          <img
            src="/userImage.jpg" // Replace with the actual image URL or path
            alt="UserImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Adjust the image sizing to cover the inner box
            }}
          />

        )}
      </Avatar>

      <Typography variant="body1"
        sx={{ fontWeight: 'normal', color: 'text.primary', paddingLeft: '10px', display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
        {userDetails?.userFirstName} {userDetails?.userLastName}
      </Typography>
    </Box>
  );
};


