
export const SearchButton = () => {


  return (
    <>
      {/* <Tooltip title="Search">
        <IconButton onClick={dialog.handleOpen}>
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <SearchDialog
        onClose={dialog.handleClose}
        open={dialog.open}
      /> */}
    </>
  );
};
