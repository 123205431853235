/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:04:10
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-09-06 16:18:21
 */
import React, { createContext, useContext, useEffect, useState } from "react";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useUser } from "src/hooks/use-user";
const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const user = useUser();
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const accessToken = sessionStorage.getItem("accessToken");
  const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [clickWithOrg, setClickWithOrg] = useState(false);
  const [clickWithPage, setClickWithPage] = useState(false);
  const setTeamName = (teamName) => {
    setSelectedTeamName(teamName);
  };
  const setTeamId = (teamId) => {
    setSelectedTeamId(teamId);
  };
  const setToClickWithOrg = (bool) => {
    setClickWithOrg(bool);
  };
  const setToClickWithPage = (bool) => {
    setClickWithPage(bool);
  };
  const updatePermission = async (id) => {
    let teamResponse = await mtpDashboardResultsApi.getTeamPermissions(
      accessToken,
      id,
      user.id
    );
    if (teamResponse) {
    //   window.location.reload();
    }
  };
  useEffect(() => {
    if (selectedTeamId) {
      updatePermission(selectedTeamId)
    }
    // eslint-disable-next-line
  }, [selectedTeamId])

  return (
    <TeamContext.Provider
      value={{
        selectedTeamId,
        setTeamId,
        setTeamName,
        selectedTeamName,
        setToClickWithOrg,
        clickWithOrg,
        setToClickWithPage,
        clickWithPage,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export const useTeam = () => {
  return useContext(TeamContext);
};
