

export const Logo = () => {


  return (
    <svg id="Layer_1" 
	data-name="Layer 1" 
	xmlns="http://www.w3.org/2000/svg" 
	viewBox="0 0 372.65 67.61">
	<circle cx="33.77" 
	cy="33.86" 
	r="33.75" 
	fill={"#583f99"} />
	<path d="M105.87,395.33" 
	transform="translate(-105.85 -362.11)"  
	fill={"#583f99"} />
	<path d="M112.09,395.33" 
	transform="translate(-105.85 -362.11)" 
	fill={"#583f99"} />
	<path d="M140.87,362.22s1,8-1,11-3,4-7,1-8,0-8,4a5.74,5.74,0,0,0,5.33,5.89c2,0,4.17-3.33,6.67-2.89,2.28.11,3.83,1.83,3.83,7.83s-.83,6.78-.83,6.78a89.3,89.3,0,0,0-9-.61c-4,0-6,3-6,4-.26,2.67,2.72,3.11,2.72,6.11,0,2.63-1.27,4.34-2.72,4.89s-5,1-7-1-1.5-4.05-.55-5.89,3-2.4,1.77-5.28-5.22-2.83-7.22-2.83-6,.11-6,.11" 
	transform="translate(-105.85 -362.11)" 
	fill={"#583f99"} 
	stroke={"#fff"}  
	stroke-strokemiterlimit={10} 
	strokeWidth={"1.75px"}  />
	<path d="M139,429.05s-.5-7.83.5-9.83,2.33-5,7.33-1c4.33,3.72,7-2,7-2a6.14,6.14,0,0,0-1.17-6.83c-4.72-3.28-6.6,1.56-9.27,1.78-2.06.16-6.56,1.05-3.81-15.2,0,0,2.14,1.53,11.42.75,4-.33,5-4.72,2.83-6.5-3.55-2.89-2.11-8.41,2.89-8.61,4.11-.17,6.33,1.44,6.44,4.39.16,4-3.56,4.22-3,7.17.73,3.77,5.84,3.83,5.84,3.83l6.83-.17" 
	transform="translate(-105.85 -362.11)" 
	fill={"#583f99"} 
	stroke={"#fff"} 
	stroke-strokemiterlimit={10} 
	strokeWidth={"1.75px"}  />
	<path d="M218.69,387.35c-1.09-6.11-4.12-10.78-9.78-10.78a6.7,6.7,0,0,0-6.56,6.45c0,10.39,19.42,4.28,19.42,17.19,0,6.06-5.61,10.33-11.32,10.33-4.92,0-7.11-2.73-8.94-2.73-1,0-1.24,1.34-1.59,2.14h-1.39V397.13h1.39c.79,5.81,3.47,12,10.23,12,5.16,0,8-2.83,8-8,0-10.13-18.93-3.77-18.93-17,0-4.07,3.63-8.89,10-8.89,4.91,0,6.9,3.13,7.64,3.13s1.05-1.34,1.64-2.54h1.49v11.58Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M256.59,393.55c0-3.77-1.44-6.15-4.42-6.15-3.43,0-6.21,3.37-6.21,6.85v12.32c0,1.49.79,2,4.07,2V410h-7.45v-16.4c0-3.77-1.44-6.15-4.42-6.15-3.43,0-6.21,3.37-6.21,6.85v12.32c0,1.49.8,2,4,2V410H224.35v-1.4c3.43,0,4.23-.49,4.23-2V390c0-1.49-.8-2-3.93-2V386.6l7.25-.3v3.43h.1c1.79-2.38,3.48-3.72,6.56-3.72,3.67,0,6.11,1.78,7.15,4.51a7.56,7.56,0,0,1,6.9-4.51c4.72,0,7.36,2.88,7.36,7.25v13.31c0,1.49.79,2,4.07,2V410h-7.45Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M284.5,393.26v13.21c0,1.49.6,1.79,1.34,1.79.94,0,1.54-.9,1.54-3.88h1.39c0,3.33-.94,5.57-4.07,5.57-2.29,0-3.38-1.49-3.48-4.28h-.1a8.46,8.46,0,0,1-7.6,4.87c-3.23,0-6.7-1.94-6.7-5.81,0-6.31,9.73-8,14.3-9.19V392c0-2.84-1.49-4.62-5.06-4.62a9.56,9.56,0,0,0-5.57,1.78l.1.1a2.14,2.14,0,0,1,2.09,2.14,2.35,2.35,0,0,1-2.34,2.43c-1.39,0-2.23-1-2.23-2.78,0-2.78,3.38-5.06,7.85-5.06C281.42,386,284.5,387.79,284.5,393.26Zm-3.38,3.57c-4.52,1-10.53,2.39-10.53,8.25a3.94,3.94,0,0,0,4.08,3.77c3.52,0,6.45-3.87,6.45-8.44Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M295.28,390c0-1.49-.8-2-3.93-2V386.6l7.3-.3v6h.1c1.15-2.88,2.93-6.25,6.51-6.25,2,0,3.82,1.19,3.82,3.42a2.23,2.23,0,0,1-2.28,2.44,2.25,2.25,0,0,1-1.09-4.23,1,1,0,0,0-.75-.24c-1.29,0-4.32,1.49-5.61,7.2-.7,3.13-.7,7.75-.7,11,0,2.49.8,3,4.47,3V410H291.3v-1.4c3.18,0,4-.49,4-2Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M326.82,386v1.39h-7.45V405c0,3.08,1.49,4.12,3.22,4.12,1.44,0,3.63-1.09,3.63-5V402h1.39v1.79c0,4-1.84,6.75-5.91,6.75-3.63,0-5.71-2-5.71-5.76V387.4h-3.68V386c4.17,0,5.37-4.08,5.66-7.45h1.4V386Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M358.65,410H333.17v-1.4c4,0,4.62-.24,4.62-3.42V380.59c0-3.18-.65-3.43-4.37-3.43v-1.39h12.42v1.39c-3.63,0-4.27.25-4.27,3.43v24.24c0,3.53.89,3.72,3.32,3.72,8.5,0,11.63-3.08,12.72-11.52H359Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M366.5,397.73v1.44c0,5.56,2.68,10,7.6,10,4.32,0,6-2,7.89-4.77l1.2.8c-3,4.37-5.32,5.36-9.49,5.36-6.81,0-11-6.06-11-12.27,0-7.25,4-12.26,10.78-12.26,6.16,0,9.69,5.86,9.69,11.72Zm12.91-1.39c0-4.08-1.24-8.94-6.31-8.94-4.57,0-6.6,4.62-6.6,8.94Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M404.44,393.26v13.21c0,1.49.6,1.79,1.34,1.79,1,0,1.54-.9,1.54-3.88h1.39c0,3.33-.94,5.57-4.07,5.57-2.28,0-3.38-1.49-3.47-4.28h-.1a8.47,8.47,0,0,1-7.6,4.87c-3.23,0-6.71-1.94-6.71-5.81,0-6.31,9.74-8,14.31-9.19V392c0-2.84-1.49-4.62-5.07-4.62a9.52,9.52,0,0,0-5.56,1.78l.1.1a2.14,2.14,0,0,1,2.08,2.14,2.34,2.34,0,0,1-2.33,2.43c-1.39,0-2.24-1-2.24-2.78,0-2.78,3.38-5.06,7.85-5.06C401.36,386,404.44,387.79,404.44,393.26Zm-3.37,3.57c-4.52,1-10.53,2.39-10.53,8.25a3.93,3.93,0,0,0,4.07,3.77c3.53,0,6.46-3.87,6.46-8.44Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M432.21,406.57c0,1.49.79,2,3.72,2V410l-7.15.29v-4.17l-.1-.15c-1.59,2.68-3.72,4.62-7.15,4.62-6.26,0-10.28-6.06-10.28-12.27s4-12.26,10-12.26c3.77,0,6.7,2.28,7.4,4.27h.2V379.35c0-1.49-.8-2-3.92-2V376l7.3-.2Zm-10.53,2.58c5.56,0,7.15-4.87,7.15-10.88,0-7.6-2.58-10.87-7.15-10.87-3.68,0-6.86,3.08-6.86,10.87S418,409.15,421.68,409.15Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M441.84,397.73v1.44c0,5.56,2.68,10,7.6,10,4.32,0,6-2,7.9-4.77l1.19.8c-3,4.37-5.31,5.36-9.49,5.36-6.8,0-11-6.06-11-12.27,0-7.25,4-12.26,10.77-12.26,6.16,0,9.69,5.86,9.69,11.72Zm12.92-1.39c0-4.08-1.25-8.94-6.31-8.94-4.57,0-6.61,4.62-6.61,8.94Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
	<path d="M464.69,390c0-1.49-.8-2-3.93-2V386.6l7.31-.3v6h.1c1.14-2.88,2.93-6.25,6.5-6.25,2,0,3.83,1.19,3.83,3.42a2.24,2.24,0,0,1-2.29,2.44,2.25,2.25,0,0,1-1.09-4.23,1,1,0,0,0-.75-.24c-1.29,0-4.32,1.49-5.61,7.2-.69,3.13-.69,7.75-.69,11,0,2.49.79,3,4.47,3V410H460.71v-1.4c3.18,0,4-.49,4-2Z" 
	transform="translate(-105.85 -362.11)" 
	fill={"#323334"} />
</svg>
  );
};
