import PropTypes from "prop-types";

import { usePopover } from "src/hooks/use-popover";
import { TenantPopover } from "./tenant-popover";

const tenants = ["Devias", "Acme Corp"];
export const TenantSwitch = (props) => {
  const popover = usePopover();

  return (
    <>
      
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      />
    </>
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
