/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-12-09 03:42:47
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { createTypography } from "src/theme/base/customTypography";
// import RemoveIcon from '@mui/icons-material/Remove';
// import PlusBUtton from '@mui/icons-material/Add';

import {
  Checkbox,
  Typography,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  // AccordionDetails,
} from "@mui/material";
import { useUser } from "src/hooks/use-user";
import { useEffect } from "react";
// import teamDashboardResultsApi from "src/services/teamdashboardApi";
import mtpDashboardResultsApi from "src/services/mtpDashboardApi";
import { useState } from "react";
import { useTeam } from "src/contexts/mtp-team-context";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Scrollbar } from "src/components/scrollbar";
// import { toast } from "react-hot-toast";
import { toast } from 'react-toastify';
import * as SldButtons from "src/theme/base/SldButtons";
import { useSidebar } from 'src/contexts/SidebarContext'; // Import the context hook
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from "@mui/material/SvgIcon";
// import MTPApiService from "src/services/mtpAPI";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { styled } from '@mui/material/styles';
import { useRef } from "react";

const SIDE_NAV_WIDTH = 210;
const SIDE_NAV_MINIMIZED_WIDTH = 50; // Adjust the width for minimized state

export default function TemporaryDrawer(props) {
  const setDrawerOpen = props.setDrawerOpen;
  const typography = createTypography();
  const user = useUser();
  const accessToken = sessionStorage.getItem("accessToken");
  const { isMinimized } = useSidebar();
  const [searchData, setSearchData] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const {
    selectedTeamId,
    setTeamId,
    // eslint-disable-next-line
    removeTeamId,
    applySelectedTeams,
    unApplySelectedTeams,
    removeAllTeamIds,
    settingOrgLevelStateTrue,
    settingOrgLevelStateFalse,
    settingOrgName,
    orgLevel
  } = useTeam();

  const [teamsList, setTeamsList] = useState([]);
  const [organisationName, setOrganisationName] = useState();
  const [selectedDivisionIds, setSelectedDivisionIds] = useState([]);
  const [tree, setTree] = useState({});
  const [originalTree, setOriginalTree] = useState({});
  const toggledItemRef = useRef({});
  const apiRef = useTreeViewApiRef();
  const [selectedItems, setSelectedItems] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);
  // const [renderKey, setRenderKey] = useState(0);

  console.log("===selectedDivisionIds===", selectedDivisionIds);

  console.log("selectedItemsvv", selectedItems);
  console.log("tree", tree);
  console.log("++====teamDetails====++", teamDetails);
console.log("selectedTeamIdnenee",selectedTeamId);

  const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.label}`]: {
      color: 'white', // Set label color to white
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      color: 'white', // Set expand icon color to white
    },
  }));


  useEffect(() => {
    // Initialize selected items based on selectedTeamId from context
    const selectedItemsFromContext = selectedTeamId.map(id => `team_${id}`);
    const selectedItemsFromContexts = [
      'team_8', 'subDivision_7'
    ];

    console.log('====================================');
    console.log("selectedItemsFromContext", selectedTeamId, selectedItemsFromContext, selectedItemsFromContexts);
    console.log('====================================');
    setSelectedItems(selectedTeamId);
    updateParentSelectionState(selectedTeamId); // Ensure parent items are selected
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamId]);

  useEffect(() => {
    if (tree?.divisions?.length > 0) {
      console.log('====================================');
      console.log("ittems", tree, selectedTeamId);
      console.log('====================================');
      const selectedNames = getSelectedIdentifiers(tree, selectedTeamId);
      console.log("kjhf", selectedNames);
      setSelectedItems(selectedTeamId);
      updateParentSelectionState(selectedTeamId);
      if (selectedNames.length === 0) {
        const selectedItemsFromContext = selectedTeamId.map(id => `team_${id}`);
        console.log("selectedItemsFromContextselectedItemsFromContext", selectedItemsFromContext);

        setSelectedItems(selectedTeamId);
        updateParentSelectionState(selectedTeamId); // Ensure parent items are selected
      }

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree?.divisions?.length > 0]);
  useEffect(() => {
    if (tree?.divisions?.length > 0) {
      const allIdentifiers = getAllIdentifiers(tree);
      const isAllSelected = allIdentifiers.every(id => selectedItems.includes(id));
      console.log('====================================');
      console.log("allIdentifiers", allIdentifiers, isAllSelected);
      console.log('====================================');
      if (isAllSelected) {
        settingOrgLevelStateTrue();
      } else {
        settingOrgLevelStateFalse();
      }
    }
    // eslint-disable-next-line
  }, [selectedItems])

  const getSelectedIdentifiers = (tree, selectedTeamIds) => {
    let selectedIdentifiers = [];

    const traverseNode = (node) => {
      let allTeamsSelected = true;

      if (node.teams) {
        const selectedTeams = node.teams.filter(team => selectedTeamIds.includes(String(team.division_category_id)));
        selectedTeams.forEach(team => selectedIdentifiers.push(`team_${team.division_category_id}`));

        if (selectedTeams.length !== node.teams.length) {
          allTeamsSelected = false;
        }
      }

      if (node.subDivisions) {
        node.subDivisions.forEach(subDiv => {
          const subDivAllTeamsSelected = traverseNode(subDiv);
          if (!subDivAllTeamsSelected) {
            allTeamsSelected = false;
          }
        });
      }

      if (allTeamsSelected) {
        if (node.type_name === 'Sub Division') {
          selectedIdentifiers.push(`subDivision_${node.division_category_id}`);
        } else if (node.type_name === 'Division') {
          selectedIdentifiers.push(`division_${node.division_category_id}`);
        }
      }

      return allTeamsSelected;
    };

    tree.divisions.forEach(division => traverseNode(division));

    tree.unassignedTeams.forEach(team => {
      if (selectedTeamIds.includes(String(team.division_category_id))) {
        selectedIdentifiers.push(`team_${team.division_category_id}`);
      }
    });

    return selectedIdentifiers;
  };

  const getAllIdentifiers = (tree) => {
    let allIdentifiers = [];

    const traverseNode = (node) => {
      let allTeamsSelected = true;

      if (node.teams) {
        node.teams.forEach(team => {
          allIdentifiers.push(`team_${team.division_category_id}`);
        });
      }

      if (node.subDivisions) {
        node.subDivisions.forEach(subDiv => {
          const subDivAllTeamsSelected = traverseNode(subDiv);
          if (!subDivAllTeamsSelected) {
            allTeamsSelected = false;
          }
        });
      }

      if (allTeamsSelected) {
        if (node.type_name === 'Sub Division') {
          allIdentifiers.push(`subDivision_${node.division_category_id}`);
        } else if (node.type_name === 'Division') {
          allIdentifiers.push(`division_${node.division_category_id}`);
        }
      }

      return allTeamsSelected;
    };

    tree.divisions.forEach(division => traverseNode(division));

    tree.unassignedTeams.forEach(team => {
      allIdentifiers.push(`team_${team.division_category_id}`);
    });

    return allIdentifiers;
  };

  const filterTree = (tree, searchTerm) => {
    const filterNode = (node) => {
      let matchedTeams = [];
      if (node.teams) {
        matchedTeams = node.teams.filter(team =>
          team.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      let matchedSubDivisions = [];
      if (node.subDivisions) {
        matchedSubDivisions = node.subDivisions
          .map(subDiv => filterNode(subDiv))
          .filter(subDiv => subDiv.teams.length > 0 || subDiv.subDivisions.length > 0 || subDiv.name.toLowerCase().includes(searchTerm.toLowerCase()));
      }

      const nodeMatches = node.name.toLowerCase().includes(searchTerm.toLowerCase());

      return {
        ...node,
        teams: matchedTeams,
        subDivisions: matchedSubDivisions,
        match: nodeMatches || matchedTeams.length > 0 || matchedSubDivisions.length > 0
      };
    };

    const filteredDivisions = tree.divisions
      .map(division => filterNode(division))
      .filter(division => division.match);

    return {
      ...tree,
      divisions: filteredDivisions
    };
  };

  // useEffect(() => {
  //   if (tree?.divisions?.length > 0) {
  //   const filteredTree = filterTree(tree, searchData);
  //   console.log('====================================');
  //   console.log("filteredTree",filteredTree);
  //   console.log('====================================');
  //   setFilteredTree(filteredTree)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchData]);
  useEffect(() => {
    if (searchData === '') {
      setTree(originalTree);
    } else {
      if (tree?.divisions?.length > 0) {
        const filteredTree = filterTree(originalTree, searchData);
        setTree(filteredTree);
      }
    }
    // eslint-disable-next-line
  }, [searchData, originalTree]);

  useEffect(() => {
    // getTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tree?.length > 0) {
      updateParentSelectionState(selectedItems);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree, selectedTeamId]);


  // const getTree = async () => {
  //   try {
  //     const response = await mtpDashboardResultsApi.getUserTeamTree(
  //       accessToken,
  //       user.id
  //     );
  //     console.log("team selector tree response", response);

  //     // setTree(response.data);
  //     // setOriginalTree(response.data);
  //     // setIsLoading(false)
  //   } catch (error) {

  //   }
  // };

  const handleOrgCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      console.log("++++came here++++");
      settingOrgLevelStateTrue();
      const allItemIds = getAllItemIds(tree);
      console.log("====allItemIds====", allItemIds);
      // updateParentSelectionState(allItemIds);
      await updateTeamDetails(allItemIds);
    } else {
      removeAllTeamIds();
      settingOrgLevelStateFalse();
      setSelectedItems([]);
      updateParentSelectionState([]);
      setTeamDetails([]);
    }
  };

  const getAllItemIds = (tree) => {
    console.log("++++tree++++", tree);
    const itemIds = [];

    const traverseNode = (node, prefix) => {
      if (node.division_category_id) {
        itemIds.push(`${prefix}${node.division_category_id}`);
      }
      if (node.subDivisions) {
        node.subDivisions.forEach(subDiv => traverseNode(subDiv, 'subDivision_'));
      }
      if (node.teams) {
        node.teams.forEach(team => traverseNode(team, 'team_'));
      }
      if (node.children) {
        node.children.forEach(child => traverseNode(child, ''));
      }
    };

    if (tree.divisions) {
      tree.divisions.forEach(division => traverseNode(division, 'division_'));
    }

    if (tree.unassignedTeams) {
      tree.unassignedTeams.forEach(team => traverseNode(team, 'team_'));
    }

    return itemIds;
  };

  const mapTreeToTreeViewData = (tree) => {
    if (!tree) {
      return [];
    }

    const mappedDivisions = (tree.divisions || []).map(division => ({
      id: `${division.division_category_id}`,
      label: `${division.name} (D)`,
      type_name: 'Division',
      parentId: `${division.division_category_id}`,
      children: [
        ...(division.subDivisions || []).map(subDivision => ({
          id: `${subDivision.division_category_id}`,
          label: `${subDivision.name} (SD)`,
          type_name: 'Sub Division',
          parentId: `${subDivision.division_category_id}`,
          children: [
            ...(subDivision.subDivisions || []).map(subSubDivision => ({
              id: `${subSubDivision.division_category_id}`,
              label: `${subSubDivision.name} (SD)`, // You can adjust naming as needed
              type_name: 'Sub Division',
              parentId: `${subDivision.division_category_id}`,
              children: (subSubDivision.teams || []).map(team => ({
                id: `${team.division_category_id}`,
                label: `${team.name} (T)`,
                type_name: 'Team',
                parentId: `${subSubDivision.division_category_id}`,
              }))
            })),
            ...(subDivision.teams || []).map(team => ({
              id: `${team.division_category_id}`,
              label: `${team.name} (T)`,
              type_name: 'Team',
              parentId: `${subDivision.division_category_id}`,
            }))
          ]
        })),
        ...(division.teams || []).map(team => ({
          id: `${team.division_category_id}`,
          label: `${team.name} (T)`,
          type_name: 'Team',
          parentId: `${division.division_category_id}`,
        }))
      ]
    }));

    const mappedUnassignedTeams = (tree.unassignedTeams || []).map(team => ({
      id: `${team.division_category_id}`,
      label: `${team.name} (T)`,
      type_name: 'Team',
      parentId: 0,
    }));

    // Combine divisions and unassigned teams directly
    return [
      ...mappedDivisions,
      ...mappedUnassignedTeams
    ];
  };

  const mappedData = mapTreeToTreeViewData(tree);
  console.log("mappedData", mappedData);
  const [expandedItems, setExpandedItems] = useState([]);
  useEffect(() => {
    if (mapTreeToTreeViewData.length > 0) {
      const allIds = getAllIds(mappedData);
      setExpandedItems(allIds);
    }
    // eslint-disable-next-line
  }, [searchData.length > 0]);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // callFunction(); // Call your function here
    }
  };
  // Function to extract all IDs recursively
  const getAllIds = (items) => {
    let ids = [];
    items.forEach(item => {
      ids.push(item.id);
      if (item.children) {
        ids = ids.concat(getAllIds(item.children));
      }
    });
    return ids;
  };
  const getItemDescendantsIds = (item) => {
    const ids = [];
    console.log("selctgh", selectedItems);
    if (item?.children) {
      item.children?.forEach((child) => {
        ids.push(child.id);
        ids.push(...getItemDescendantsIds(child));
      });
    } else {
      ids.push(item.id);
    }

    console.log("itemdsds", item, ids);
    return ids;
  };

  const getAncestorIds = (itemId) => {
    const ancestors = [];
    const item = apiRef.current.getItem(itemId);

    // Utility to convert type_name to camelCase
    const toCamelCase = (str) => {
      return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '');
    };

    const findParent = (currentId, items) => {
      for (const item of items) {
        if (item.children && item.children.some(child => child.id === currentId)) {
          // Push the ID with camelCase type_name prefix
          ancestors.push(`${toCamelCase(item.type_name)}_${item.id}`);
          findParent(item.id, mappedData); // Recursively find parent
        }
        if (item.children) {
          findParent(currentId, item.children); // Check deeper levels
        }
      }
    };

    findParent(itemId, mappedData);

    // Add the current item to the ancestors list
    if (item) {
      ancestors.unshift(`${toCamelCase(item.type_name)}_${item.id}`);
    }

    return ancestors;
  };


  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    event.stopPropagation();
    
    // Store toggle in the reference
    toggledItemRef.current[itemId] = isSelected;
  
    // Immediately handle descendants
    const item = apiRef.current.getItem(itemId);
    const descendants = getItemDescendantsIds(item);
  
    if (isSelected) {
      // Add descendants to toggledItemRef as well
      descendants.forEach(descId => {
        toggledItemRef.current[descId] = true;
      });
    } else {
      // Remove descendants from toggledItemRef
      descendants.forEach(descId => {
        toggledItemRef.current[descId] = false;
      });
    }
  };

  // const handleSelectedItemsChange = (event, newSelectedItems) => {
  //   event.stopPropagation();
  
  //   // Determine items to select/unselect based on toggledItemRef
  //   const itemsToSelect = [];
  //   const itemsToUnselect = [];
  
  //   // Convert toggled items to arrays
  //   for (const [itemId, isSelected] of Object.entries(toggledItemRef.current)) {
  //     const item = apiRef.current.getItem(itemId);
  //     const descendants = getItemDescendantsIds(item);
  //     // Include the item itself
  //     descendants.push(itemId);
  
  //     if (isSelected) {
  //       // Add all descendants to itemsToSelect
  //       itemsToSelect.push(...descendants);
  //     } else {
  //       // Add all descendants to itemsToUnselect
  //       itemsToUnselect.push(...descendants);
  //     }
  //   }
  
  //   // Compute final selected items
  //   let finalSelected = new Set(selectedItems);
  
  //   // Remove items that should be unselected
  //   itemsToUnselect.forEach(id => {
  //     finalSelected.delete(id);
  //   });
  
  //   // Add items that should be selected
  //   itemsToSelect.forEach(id => {
  //     finalSelected.add(id);
  //   });
  
  //   // Convert back to array
  //   const updatedSelectedItems = Array.from(finalSelected);
  
  //   // Update state
  //   setSelectedItems(updatedSelectedItems);
  
  //   // Clear toggled references
  //   toggledItemRef.current = {};
  
  //   // Optionally update parent states if desired
  //   updateParentSelectionState(updatedSelectedItems);
  //   updateTeamDetails(updatedSelectedItems);
  // };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    event.stopPropagation();
    const itemsToSelect = [];
    const itemsToUnselect = {};
    console.log("newSelectedItems", newSelectedItems);

    // Utility to convert type_name to camelCase
    const toCamelCase = (str) => {
      return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '');
    };

    // Analyze toggled items
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);

      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnselect[descendantId] = true;
        });
        getAncestorIds(itemId).forEach((ancestorId) => {
          itemsToUnselect[ancestorId] = true;
        });
      }
    });
    console.log("itemsToSelect", itemsToSelect, itemsToUnselect);

    // Deduplicate and add type_name prefix to IDs
    const deduplicatedSelectedItems = newSelectedItems.reduce((result, itemId) => {
      const baseId = itemId.includes('_') ? itemId.split('_')[1] : itemId;
      const hasPrefixedVersion = result.some((id) => id.endsWith(`_${baseId}`));

      if (!hasPrefixedVersion) {
        if (itemId.includes('_')) {
          result.push(itemId); // Already prefixed
        } else {
          const item = apiRef.current.getItem(baseId);
          if (item) {
            result.push(`${toCamelCase(item.type_name)}_${item.id}`);
          }
        }
      }
      return result;
    }, []);

    // Manage child and ancestor relationships
    const newSelectedItemsWithChildren = Array.from(
      new Set([...deduplicatedSelectedItems, ...itemsToSelect])
    ).filter((itemId) => !itemsToUnselect[itemId]);

    const updatedSelectedItems = [...newSelectedItemsWithChildren];
    newSelectedItemsWithChildren.forEach((itemId) => {
      const ancestorIds = getAncestorIds(itemId);
      updatedSelectedItems.push(...ancestorIds);
    });

    console.log('Updated Selected Items:', updatedSelectedItems);
    console.log('newSelectedItemsWithChildren Items:', newSelectedItemsWithChildren);
    // // Update selected items state
    setSelectedItems(updatedSelectedItems);

    // Remove unselected items logic
    Object.keys(itemsToUnselect).forEach((itemId) => {
      if (itemId.startsWith('team_') || itemId.startsWith('unassigned_team_')) {
        // eslint-disable-next-line
        const teamId = itemId.replace('team_', '').replace('unassigned_', '');
        // removeTeamId(teamId);
        unApplySelectedTeams();
        settingOrgLevelStateFalse();
      }
    });

    // Reset toggled items reference
    // toggledItemRef.current = {};

    // Trigger any required parent-child state updates
    updateParentSelectionState(newSelectedItemsWithChildren);
    updateTeamDetails(newSelectedItemsWithChildren);
  };

  const updateParentSelectionState = async (selectedItems) => {
    const updatedSelectedItems = {};
  
    // Mark all selected items as true
    selectedItems.forEach((itemId) => {
      updatedSelectedItems[itemId] = true;
    });
  
    // Recursive function to update parent selection state
    const updateParentState = (items) => {
      items.forEach((item) => {
        if (item.children) {
          let allSelected = true;
          let allUnselected = true;
  
          // Check children's selection status
          item.children.forEach((child) => {
            if (!updatedSelectedItems[child.id]) {
              allSelected = false; // If at least one child is unselected
            } else {
              allUnselected = false; // If at least one child is selected
            }
          });
  
          // Mark parent as selected if all children are selected
          if (allSelected) {
            updatedSelectedItems[item.id] = true;
          } 
          // Mark parent as unselected if all children are unselected
          else if (allUnselected) {
            updatedSelectedItems[item.id] = false;
          } 
          // Otherwise, mark as indeterminate
          else {
            updatedSelectedItems[item.id] = 'indeterminate';
          }
  
          // Recursively process the parent's children
          updateParentState(item.children);
        }
      });
    };
  
    // Start the recursive process from the root
    updateParentState(mappedData);
  
    // Update the selected items state based on the updated selection
    setSelectedItems(
      Object.keys(updatedSelectedItems).filter((itemId) => updatedSelectedItems[itemId] === true)
    );
  };
  

  const updateTeamDetails = (selectedItems) => {
    return new Promise((resolve) => {
      console.log("++++selectedItems++++", selectedItems);
      const updatedTeamDetails = selectedItems
        .filter(itemId => itemId.startsWith('team_') || itemId.startsWith('unassigned_team_'))
        .map(itemId => {

          const teamId = itemId.replace('team_', '').replace('unassigned_', '');
          const item = apiRef.current.getItem(teamId);
          console.log("=====item====", item, itemId);
          return { teamId, teamName: item.label };
        });
      setTeamDetails(updatedTeamDetails);
      resolve();
    });
  };

  const fetchUsersTeams = async () => {
    try {
      const response = await mtpDashboardResultsApi.getUserTeamTree(
        accessToken,
        user.id
      );
      if (response.message) {
        toast.error(response.message)
        setIsLoading(false);
      } else {
        console.log("came here in else condition");
        console.log("response teams", response);
        setTeamsList(response.teamTree);
        setOrganisationName(response.organisationName[0]);
        settingOrgName(response.organisationName[0].OrganisationName);
        setIsLoading(false);
        setIsLoading(false);
        setTree(response.data);
        setOriginalTree(response.data);
      }

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // if()
    // getTree();
    fetchUsersTeams();
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // Check if the click event originated from within a checkbox
    if (event.target.tagName === "INPUT" && event.target.type === "checkbox") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  const handleSubmit = () => {
    console.log("came hereeeee");
    console.log("+_+_teamDetails+_+_", teamDetails);
    removeAllTeamIds();
    if (teamDetails && teamDetails.length > 0) {
      teamDetails.forEach(team => {
        setTeamId(team.teamId, team.teamName, '');
      });
      setState({ ...state, left: false });
      setDrawerOpen(false);
      applySelectedTeams();
    }

  };

  useEffect(() => {
    if (selectedTeamId) {
      console.log("selectedTeamId", selectedTeamId);
    }
  }, [selectedTeamId]);

  // Effect to handle the initial state of division checkboxes
  useEffect(() => {
    console.log("Running here");
    const updateDivisionStates = () => {
      teamsList.forEach((division) => {
        const teamsInDivision = division.teams;
        let allTeamsSelected = true;
        let allTeamsUnselected = true;

        teamsInDivision.forEach((team) => {
          if (!selectedTeamId.includes(team.team_category_id)) {
            allTeamsSelected = false;
          } else {
            allTeamsUnselected = false;
          }
        });

        const divisionCheckbox = document.getElementById(`division-checkbox-${division.division_id}`);
        if (divisionCheckbox) {
          if (allTeamsSelected) {
            divisionCheckbox.indeterminate = false;
            divisionCheckbox.checked = true;
            setSelectedDivisionIds((prevIds) => {
              if (!prevIds.includes(division.division_id)) {
                return [...prevIds, division.division_id];
              }
              return prevIds;
            });
          } else if (allTeamsUnselected) {
            divisionCheckbox.indeterminate = false;
            divisionCheckbox.checked = false;
            setSelectedDivisionIds((prevIds) => prevIds.filter((id) => id !== division.division_id));
          } else {
            divisionCheckbox.indeterminate = true;
            setSelectedDivisionIds((prevIds) => prevIds.filter((id) => id !== division.division_id));
          }
        }
      });
    };

    updateDivisionStates();
  }, [selectedTeamId, teamsList]);

  // Additional useEffect to ensure the correct initial state
  useEffect(() => {
    console.log("++++Came here++++");
    // Initialize the state correctly once the component mounts and teamsList is set
    const initializeState = () => {
      console.log("function executing");
      teamsList.forEach((division) => {
        console.log("division", division);
        const teamsInDivision = division.teams;
        let allTeamsSelected = true;
        let allTeamsUnselected = true;

        teamsInDivision.forEach((team) => {
          console.log("team", team);
          if (!selectedTeamId.includes(team.team_category_id)) {
            allTeamsSelected = false;
          } else {
            allTeamsUnselected = false;
          }
        });

        const divisionCheckbox = document.getElementById(`division-checkbox-${division.division_id}`);
        console.log("divisionCheckbox", divisionCheckbox);
        if (divisionCheckbox) {
          console.log("if divisionCheckbox");
          if (allTeamsSelected) {
            console.log("if allTeamsSelected");
            divisionCheckbox.indeterminate = false;
            divisionCheckbox.checked = true;
            setSelectedDivisionIds((prevIds) => {
              if (!prevIds.includes(division.division_id)) {
                return [...prevIds, division.division_id];
              }
              return prevIds;
            });
          } else if (allTeamsUnselected) {
            console.log("else if allTeamsUnselected");
            divisionCheckbox.indeterminate = false;
            divisionCheckbox.checked = false;
            setSelectedDivisionIds((prevIds) => prevIds.filter((id) => id !== division.division_id));
          } else {
            console.log(" final else ");
            divisionCheckbox.indeterminate = true;
            setSelectedDivisionIds((prevIds) => prevIds.filter((id) => id !== division.division_id));
          }
        }
      });
    };

    console.log("before hello");
    // fetchUsersTeams();
    initializeState();
    console.log("after hello");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("----state------", state);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : { lg: '310px', md: '310px', sm: '280px', xs: '280px' },
        height: "100%",
        backgroundColor: "#583F99",
        borderRadius: '0 15px 15px 0',
        zIndex: 1201,
        paddingLeft: { lg: '45px', md: '45px', sm: '5px', xs: '5px' },
        paddingTop: '25px',
        '& .MuiAccordionSummary-content .MuiFormControlLabel-root': { marginLeft: '0!important' },
        '& .MuiButtonBase-root': { padding: '4px 3px!important' }


      }}
      role="presentation"
      onClick={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      onKeyDown={(event) => {
        if (event.target !== event.currentTarget) return; // Don't close on input events
        toggleDrawer(anchor, false);
      }}
      p={2}
    >
      <Box
        component="form"
        sx={{
          flexGrow: 1,
          marginTop: 1,
          marginLeft: 1,
          marginRight: 1,
          marginBottom: 1,

        }}
      >
        <Typography
          sx={{ ...typography.subtitle.regularColorless, color: "white", marginBottom: '5px', fontSize: '13px' }}
        >
          Multi-Team Selector
        </Typography>

        <OutlinedInput
          value={searchData}
          autoFocus
          fullWidth
          onKeyDown={handleKeyDown}
          sx={{
            '& .MuiInputBase-input': { padding: '8px 0!important' },
            '&.MuiInputBase-root': { backgroundColor: '#fff!important' },
            '& .MuiInputAdornment-root path': { fill: '#535B66' },
            '& .MuiSvgIcon-root': { width: '16px', height: '16px' },
            ' input::placeholder': { color: '#535B66', fontSize: '12px' }

          }}
          placeholder="Search"
          onChange={(e) => setSearchData(e.target.value)}
          startAdornment={
            <InputAdornment position="start"
            >
              <SvgIcon>
                <SearchIcon fontSize="small" />
              </SvgIcon>
            </InputAdornment>
          }
        />
      </Box>


      <Scrollbar style={{ maxHeight: '100%', width: '100%' }}
        sx={{
          '& .MuiTreeItem-content': { gap: '0px', padding: '2px!important', alignItems: 'flex-start' },
          '& .MuiButtonBase-root': { padding: '2px' },
          '& .MuiButtonBase-root svg': { height: '18px' },
          '& .MuiTreeItem-label': { fontSize: '13px!important', lineHeight: '16px' },
          '& .MuiButtonBase-root.MuiCheckbox-root': { padding: '0!important' },


        }}>
        {organisationName && (
          <List>

            <div >
              <Accordion
                sx={{ backgroundColor: "transparent", }}>
                <AccordionSummary

                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          ...typography.subtitle.regularColorless,
                          color: "white",

                        }}
                      >
                        {organisationName.OrganisationName}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={orgLevel}
                        onChange={(event) => {
                          handleOrgCheckboxChange(event);
                        }}
                      />
                    }
                  />
                </AccordionSummary>

              </Accordion>
            </div>

          </List>
        )}
        {searchData.length > 0 ?
          <RichTreeView
            // key={renderKey}
            apiRef={apiRef}
            multiSelect
            checkboxSelection
            items={mappedData}
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemsChange}
            onItemSelectionToggle={handleItemSelectionToggle}
            slots={{ item: CustomTreeItem }}
            expandedItems={expandedItems ? expandedItems : []}
            onExpandedItemsChange={undefined}
          /> :
          <RichTreeView
            // key={renderKey}
            apiRef={apiRef}
            multiSelect
            checkboxSelection
            items={mappedData}
            selectedItems={selectedItems}
            onSelectedItemsChange={handleSelectedItemsChange}
            onItemSelectionToggle={handleItemSelectionToggle}
            slots={{ item: CustomTreeItem }}
          />
        }

      </Scrollbar>
      <div style={{ alignSelf: "center" }}>
        <SldButtons.WhiteButtonCap onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ position: 'absolute', bottom: '15px', right: '15px' }}>
          Apply
        </SldButtons.WhiteButtonCap>
      </div>
    </Box>
  );

  React.useEffect(() => {
    // Open the drawer directly when the component mounts
    setState((prev) => ({ ...prev, left: true }));
  }, []);

  //loader animation will run until response fetched from backend
  if (isLoading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={state["left"]}
      onClose={() => {
        setDrawerOpen(false);
        toggleDrawer("left", false);
      }}

      sx={{

        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0)!important', // Set the background color of the backdrop to transparent

        },
        '& .MuiPaper-root.MuiPaper-elevation': {
          left: {
            lg: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            md: isMinimized ? SIDE_NAV_MINIMIZED_WIDTH : SIDE_NAV_WIDTH,
            sm: 0,
            xs: 0,
          },
          top: { lg: '0', md: '0', sm: '155px', xs: '155px' },
          zIndex: -1, overflow: 'hidden',
          height: { lg: '100%', md: '100%', sm: '70%', xs: 'calc(100vh - 200px)' }
        },

        '& .MuiPaper-root.MuiPaper-elevation .MuiPaper-root.MuiPaper-elevation': {
          left: 0, zIndex: 1, top: { lg: ' ', md: '', sm: '0', xs: '0' },
          height: '100%'
        },
        '& .MuiButtonBase-root': {

          '& rect': {
            fill: '#fff',
            stroke: '#fff;'
          }

        },
        '& .MuiAccordionDetails-root .MuiBox-root': { marginLeft: 0 },
        '& .MuiAccordionSummary-root': { borderBottom: '1px solid rgba(255,255,255,.3)', borderTop: '1px solid rgba(255,255,255,.3)' },
        '& .MuiAccordionSummary-content': { margin: '2px 0!important' },
        '& .MuiAccordionSummary-root.Mui-expanded': { minHeight: '25px', margin: '0px 0' },
        '& .MuiButtonBase-root.MuiCheckbox-root': { padding: '4px' },
        '& .MuiFormControlLabel-root .MuiTypography-root': { paddingLeft: 0 },
        '& .MuiAccordionSummary-root svg path': { fill: '#fff!important' },
        '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': { color: '#fff' },
        '& .MuiAccordionSummary-content .MuiTypography-root': { fontSize: '12px' },
        '& .MuiAccordionDetails-root .MuiTypography-root': { fontSize: '12px' },
        '& .MuiList-root .MuiPaper-root.MuiPaper-elevation': { borderRadius: '0!important' }

      }}

    >
      {list("left")}
    </Drawer>
  );
}
