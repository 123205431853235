/**
 * @Author: Anandp
 * @Date:   2024-07-25 06:41:01
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-11-01 13:13:56
 */
import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import * as SldButtons from "src/theme/base/SldButtons";
import { useLocation } from 'react-router-dom';
import { purple } from "src/theme/colors";
import { useSidebar } from "src/contexts/SidebarContext";
import { useUser } from 'src/hooks/use-user';
import CryptoJS from 'crypto-js';



const DashboardMenu = () => {
  const user = useUser();
  const [dbmenu, setDbmenu] = useState('');
  // const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  // const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  const dashboardPerms = CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  const navigate = useNavigate();
  const { isMinimized } = useSidebar();
  const handleNavigate = () => {

    navigate("/dashboard");
  };
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setDbmenu(selectedValue);

    // Navigate based on the selected value
    switch (selectedValue) {
      case 10:
        navigate('/leadSelf?navigated=true');
        break;
      case 20:
        navigate('/teamDiversity?navigated=true');
        break;
      case 30:
        navigate('/MTP');
        break;
      default:
        break;
    }
  };
  const location = useLocation();

  return (
    <>
      <ul
        style={{ listStyle: 'none', margin: '0', padding: '0', }}>
        <li>
          <SldButtons.NavbarButton
            onClick={handleNavigate}
            className={location.pathname === '/dashboard' ? 'active' : ''}
            sx={{
              '&.MuiButtonBase-root.active path': { fill: 'white' },
              '&.MuiButtonBase-root.active': { backgroundColor: `${purple.main} !important`, color: 'white' },

            }}
          >
            <HomeIcon

              fontSize={'small'}
              style={{ marginRight: '8px', }} /> {!isMinimized && ('Home')} </SldButtons.NavbarButton></li></ul>
      {!isMinimized && (

        <FormControl
          sx={{ m: 1, minWidth: 120, marginBottom: '20px!important' }}
          size="small">
          <InputLabel id="demo-select-small-label"
            className='joyride-target-1'>Dashboard Menu</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={dbmenu}
            label="Dashboard Menu"
            onChange={handleChange}
            sx={{
              borderRadius: '10px', '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd',
                boxShadow: `${purple.main} 0 0 0 0px`,
              },
              '& .MuiSelect-select': { padding: '7px' }
            }}
          >

            <MenuItem value={10}>Lead Self</MenuItem>
            {
              dashboardPerms && dashboardPerms.includes(14) &&
              <MenuItem value={20}>Team </MenuItem>
            }
            {
              surveyPerms && surveyPerms.includes(6) &&
              <MenuItem value={30}>MyTeamPulse</MenuItem>
            }

          </Select>
        </FormControl>
      )}
    </>
  );
};

export default DashboardMenu
