
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';

import { usePopover } from 'src/hooks/use-popover';

import { AccountPopover } from './account-popover';
import { AccountIcon } from './account-icon';


export const AccountButton = () => {
  const user = AccountIcon();
  const popover = usePopover();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
       flexDirection :'row',
       justifyContent:'flex-end',
       width:'100%',
       display:'flex',
       paddingLeft:1
        }}
      >
        {user}
      </Box> 
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
