import { Grid } from "@mui/material";
import React from "react";

function AddGoalLayout(props) {
  const { children } = props;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      
      spacing={3}
      sx={{ backgroundColor: "#535B661A",padding:{lg:3, md:3, sm:0, xs:0} }}
    >
      <Grid item
lg={10}
md={10}
sm={12}
xs={12}
>
        {children}
      </Grid>
    </Grid>
  );
}

export default AddGoalLayout;
