import { lazy } from "react";

const Survey = lazy(() => import("src/layouts/survey/surveyStart"));

export const surveyRoutes = [
  {
    path: "surveyQuestions",
    children: [
      {
        index: true,
        element: <Survey/>,
      },
    ],
  },
];
