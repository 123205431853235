import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";


const TestingPage = lazy(()=>import("src/pages/testingPage"));

export const testingPage = [
  {
    path: "",
    element: (  
        <Suspense>    
          <Outlet />
        </Suspense>
    ),
    children: [
      {
        path: "testingPage",
        index: true,
        element: <TestingPage />,
      }
    ],
  },
];
